"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeamPerformanceInsightsSchema = exports.OverallPerformanceSchema = exports.UserPerformanceSchema = exports.UserUsageSchema = exports.SessionsInWeekSchema = exports.StoriesInWeekSchema = exports.FirstStepsGoalsSchema = exports.TrendType = void 0;
const zod_1 = require("zod");
var TrendType;
(function (TrendType) {
    TrendType["Up"] = "up";
    TrendType["Down"] = "down";
    TrendType["Flat"] = "flat";
})(TrendType || (exports.TrendType = TrendType = {}));
exports.FirstStepsGoalsSchema = zod_1.z.object({
    firstStoryCompleted: zod_1.z.boolean(),
    firstTrackCompleted: zod_1.z.boolean(),
    firstRefresherCompleted: zod_1.z.boolean(),
});
// dashboard types
exports.StoriesInWeekSchema = zod_1.z.object({
    number: zod_1.z.number(),
});
exports.SessionsInWeekSchema = zod_1.z.object({
    number: zod_1.z.number(),
});
exports.UserUsageSchema = zod_1.z.object({
    userId: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    avgRatingForAllJourneySessions: zod_1.z.number(),
    totalStories: zod_1.z.number(),
    totalSessions: zod_1.z.number(),
    storiesInWeek: exports.StoriesInWeekSchema,
    sessionsInWeek: exports.SessionsInWeekSchema,
});
exports.UserPerformanceSchema = zod_1.z.object({
    userId: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    averageFirstSessionScore: zod_1.z.number(),
    averageSecondSessionScore: zod_1.z.number().nullable(),
    averageImprovement: zod_1.z.number().nullable(),
    averagePerformanceTrend: zod_1.z.nativeEnum(TrendType),
});
exports.OverallPerformanceSchema = zod_1.z.object({
    averageFirstSessionScore: zod_1.z.number(),
    averageSecondSessionScore: zod_1.z.number().nullable(),
});
exports.TeamPerformanceInsightsSchema = zod_1.z.object({
    userPerformances: zod_1.z.array(exports.UserPerformanceSchema),
    overallPerformance: exports.OverallPerformanceSchema,
});
