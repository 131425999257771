import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { LockKey } from '@phosphor-icons/react';
import { StoryCard } from '../../../common/components/StoryCard';
import { useTranslation } from 'react-i18next';

export const LearnFromRealCalls = () => {
	const { t } = useTranslation('home');
	return (
		<Box mt="4" p="3" bg="gray.50" cursor={'not-allowed'}>
			<Flex mb="6" alignItems={'center'} gap="2">
				<Text
					w="fit-content"
					fontSize="2xl"
					color={'#757575'}
					fontWeight="semibold"
				>
					{t('learnFromRealCalls')}
				</Text>
				<LockKey color={'#757575'} fontWeight="500" size={30} />
			</Flex>
			<SimpleGrid spacing={5} columns={[1, 1, 2, 3]}>
				<StoryCard
					storyId="1"
					type="analytics"
					person="Stafani Heiner - July 5, 2023"
					title="Learn from your call with Jessica"
					role="CTO @BusinessAi"
					tags={['It Solutions', 'Warm Call']}
				/>
				<StoryCard
					storyId="2"
					type="analytics"
					person="Stafani Heiner - July 5, 2023"
					title="Learn from your call with Jessica"
					role="CTO @BusinessAi"
					tags={['It Solutions', 'Warm Call']}
				/>
				<StoryCard
					storyId="3"
					type="analytics"
					person="Stafani Heiner - July 5, 2023"
					title="Learn from your call with Jessica"
					role="CTO @BusinessAi"
					tags={['It Solutions', 'Warm Call']}
				/>
			</SimpleGrid>
		</Box>
	);
};
