import { createIcon } from '@chakra-ui/react';

export const XpIcon = createIcon({
	displayName: 'XpIcon',
	viewBox: '0 0 24 24',
	path: [
		<rect key="background" width="24" height="24" rx="12" fill="#EBF934" />,
		<path
			key="text"
			d="M11.6576 16H10.1456L8.18958 12.952L6.24558 16H4.76958L7.51758 11.98L4.98558 8.08H6.49758L8.30958 10.96L10.1456 8.08H11.5496L8.98158 11.932L11.6576 16ZM16.183 8.08C16.751 8.08 17.239 8.18 17.647 8.38C18.063 8.58 18.387 8.864 18.619 9.232C18.851 9.6 18.967 10.044 18.967 10.564C18.967 11.076 18.851 11.52 18.619 11.896C18.387 12.264 18.063 12.548 17.647 12.748C17.239 12.948 16.751 13.048 16.183 13.048H14.419V16H13.135V8.08H16.183ZM15.979 11.944C16.547 11.944 16.963 11.828 17.227 11.596C17.499 11.356 17.635 11.012 17.635 10.564C17.635 10.108 17.499 9.764 17.227 9.532C16.963 9.292 16.547 9.172 15.979 9.172H14.419V11.944H15.979Z"
			fill="black"
		/>,
	],
});
