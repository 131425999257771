import { SolvedStatus } from '@jam/api-types';
import {
	useGetAllSessionsQuery,
	useGetUserJourneyContentAllocationQuery,
} from '../../../redux/api/learnerApi';

type UserProgress = {
	itemsCompleted: number;
	storiesCompleted: number;
	tracksCompleted: number;
};

export const useUserProgress = (): UserProgress | null | undefined => {
	const { data: calls, isLoading } = useGetAllSessionsQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});
	const { data: userJourney, isLoading: userJourneyIsLoading } =
		useGetUserJourneyContentAllocationQuery();

	if (isLoading || userJourneyIsLoading) return undefined;

	if (!userJourney) return null;
	const completedStories = userJourney.trackStatuses.flatMap(
		(trackStatus) => trackStatus.completedStoryIds
	);

	if (!calls) return null;

	const completedTracks = userJourney.trackStatuses.filter(
		(track) => track.hasFinished
	).length;

	const completedCalls = calls.filter((call) =>
		call.analysis?.items.every((i) => i.solved === SolvedStatus.Solved)
	);

	// items completed
	const itemsCompleted = completedCalls
		.filter((cc) => completedStories.includes(cc.storyReferenceId))
		.reduce((acc, call) => acc + (call.analysis?.items.length || 0), 0);

	return {
		itemsCompleted,
		storiesCompleted: completedStories.length,
		tracksCompleted: completedTracks,
	};
};
