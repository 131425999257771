import { Box, Flex, Text, Avatar, Image, Skeleton } from '@chakra-ui/react';
import { List } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { useNavigate } from 'react-router-dom';
import { Chip } from '../../../common/components/Chip';
import { useGetUserTotalExperienceQuery } from '../../../redux/api/learnerApi';

type SalutationProps = {
	text: string;
};

const LoadingState = ({ isLoaded }: { isLoaded: boolean }) => {
	return (
		<Skeleton
			height={'32px'}
			w={'70px'}
			isLoaded={isLoaded}
			borderRadius={'16px'}
		/>
	);
};

export const Salutation = ({ text }: SalutationProps) => {
	const { user: authUser } = useAuthInfo();
	const navigate = useNavigate();
	const { data: exp, isLoading } = useGetUserTotalExperienceQuery();
	const name = authUser?.firstName || '';
	const profileImage = authUser?.pictureUrl || '';
	return (
		<Box w="100%" mb="5" borderRadius={'md'}>
			<Flex
				direction={'row'}
				gap={10}
				justifyContent={'space-between'}
				alignItems={{ base: 'column', lg: 'center' }}
				w="100%"
			>
				<Flex direction={'column'} gap={'24px'}>
					<Text fontSize="3xl" fontWeight="medium">
						{text}
					</Text>
				</Flex>
				<Flex gap={2}>
					<LoadingState isLoaded={!isLoading} />
					{typeof exp === 'number' && exp >= 0 && (
						<Chip
							icon={<Image src="/icons/black-xp.svg" />}
							h="32px"
							px="3"
							bg="#EBF934"
							color={'black'}
							text={`${exp}`}
						/>
					)}
					<Flex
						bg="white"
						w="fit-content"
						borderRadius={'full'}
						pr="1"
						cursor={'pointer'}
						borderWidth={'1px'}
						borderColor={'gray.200'}
						alignItems="center"
						gap="6px"
						onClick={() => {
							navigate('/settings');
						}}
					>
						<Avatar size="sm" src={profileImage} name={name} bg="grey" />
						<List width={'16px'} height={'16px'} />
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
};
