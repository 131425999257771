import { useQueryParam, withDefault, StringParam } from 'use-query-params';

export const useTrack = () => {
	const [track, setTrack] = useQueryParam<string | null>(
		'track',
		withDefault(StringParam, null)
	);

	return { track, setTrack };
};
