import { Stack, Divider } from '@chakra-ui/react';
import { UsersTable } from './UsersTable';
import { PendingInvites } from './PendingInvites';

export const CompanySettingsTab = () => {
	return (
		<Stack direction="column" spacing="5" divider={<Divider />} py="10">
			<PendingInvites />
			<UsersTable />
		</Stack>
	);
};
