import {
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
	useToast,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useDisableUserMutation } from '../../../redux/api/accountApi';
import { useTranslation } from 'react-i18next';

type RemoveUserDialogProps = {
	userId: string | null;
	isOpen: boolean;
	onClose: () => void;
};

const RemoveUserDialog = ({
	isOpen,
	onClose,
	userId,
}: RemoveUserDialogProps) => {
	const cancelRef = useRef<HTMLButtonElement | null>(null);
	const { t } = useTranslation('settings');
	const [disableUser, { isLoading }] = useDisableUserMutation();
	const toast = useToast();

	const handleRemoveUser = async () => {
		if (!userId) return;
		await disableUser({ userId });
		onClose();
		toast({
			title: t('user-disabled-successfully'),
			status: 'success',
			duration: 3000,
			isClosable: true,
		});
	};
	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						{t('disable-user')}
					</AlertDialogHeader>

					<AlertDialogBody>{t('disable-user-confirmation')}</AlertDialogBody>

					<AlertDialogFooter>
						<Button
							id="disable-user-dialog-button"
							borderRadius={'full'}
							type="submit"
							px="6"
							bgColor={'red.500'}
							size="md"
							isLoading={isLoading}
							onClick={() => {
								void handleRemoveUser();
							}}
							mr={3}
						>
							{t('disable-user')}
						</Button>
						<Button
							borderRadius={'32px'}
							textColor={'black'}
							bg={'white'}
							gap={'16px'}
							border={'1px'}
							_hover={{ bg: 'white' }}
							onClick={() => {
								onClose();
							}}
						>
							{t('cancel')}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};

export default RemoveUserDialog;
