import React from 'react';
import { XAxis, YAxis, Line, LineChart, Tooltip } from 'recharts';
import { UsageByWeekData } from '../../../data';
import { Box, Link, Text } from '@chakra-ui/react';
import { useActiveScreen } from '../../../../hooks/useActiveScreen';

type UsageByWeekChartProps = {
	width: number;
	showLearnMore?: boolean;
};

export const UsageByWeekChart = ({
	width,
	showLearnMore,
}: UsageByWeekChartProps) => {
	const { setActiveScreen } = useActiveScreen();
	return (
		<Box py="4" pr="10" w={width}>
			<Box height={'100px'}>
				<Text mb="2" ml="16" fontSize={'2xl'} fontWeight={'semibold'}>
					Usage
				</Text>
				<Text
					mb="4"
					ml="16"
					fontSize={'sm'}
					color="gray.500"
					fontWeight={'medium'}
				>
					Completed stories and completed sessions per user.
				</Text>
			</Box>
			<LineChart width={width} height={250} data={UsageByWeekData}>
				<XAxis dataKey="name" />
				<YAxis />
				<Tooltip />
				<Line
					type="monotone"
					name="Stories per User"
					dataKey="storiesPerUser"
					stroke="#8884d8"
				/>
				<Line
					type="monotone"
					name="Sessions per User"
					dataKey="sessionsPerUser"
					stroke="#82ca9d"
				/>
			</LineChart>
			{showLearnMore && (
				<Link
					_hover={{ textDecoration: 'underline' }}
					ml="10"
					color="blue.500"
					onClick={() => setActiveScreen(1)}
				>
					Learn More
				</Link>
			)}
		</Box>
	);
};
