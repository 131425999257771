import React from 'react';
import { Link, useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Flex, Stack, Text } from '@chakra-ui/react';

const ErrorPage: React.FC = () => {
	const error = useRouteError();
	Sentry.captureException(error);

	return (
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			height="100vh"
			p={4}
		>
			<Stack
				maxWidth="600px"
				direction="column"
				spacing={2}
				sx={{ textAlign: 'center' }}
				justifyContent="center"
				alignItems="center"
			>
				<Text sx={{ fontSize: 18, lineHeight: '28px', fontWeight: 700 }}>
					System error
				</Text>
				<Text variant="subtitle1">
					Please try reloading the page or restarting the app.
				</Text>
				<Text variant="subtitle1">
					Sorry for the inconvenience, but something went wrong! If this keeps
					happening,{' '}
					<Link
						to="#"
						onClick={(e) => {
							e.preventDefault();
							window.location.href = 'mailto:support@support.com';
							// TODO: add support email + phone
						}}
					>
						contact customer service
					</Link>
					.
				</Text>
				<Text variant="subtitle1">Thank you!</Text>
			</Stack>
		</Flex>
	);
};

export default ErrorPage;
