import { Flex } from '@chakra-ui/react';
import React from 'react';
import { TeamPerformanceChart } from './TeamPerformanceChart';
import { TeamPerformanceTable } from './TeamPerformanceTable';
import { useGetTeamPerformanceInsightsQuery } from '../../../../../../redux/api/dashboardApi';

export const TeamPerformanceScreen = () => {
	const { data } = useGetTeamPerformanceInsightsQuery();
	if (!data) return null;
	return (
		<Flex gap={'5'} direction={'column'}>
			<TeamPerformanceChart />
			<TeamPerformanceTable data={data.userPerformances} />
		</Flex>
	);
};
