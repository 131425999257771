import { Flex } from '@chakra-ui/react';
import { TrackDTO } from '@jam/api-types';
import { Clock } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { Chip } from './Chip';
import { XpIcon } from './Icons';

type TrackLabelsProps = { track: TrackDTO };

export const TrackLabels = ({ track }: TrackLabelsProps) => {
	const { t } = useTranslation('story');

	if (!track) {
		return null;
	}

	return (
		<Flex flexWrap={'wrap'} gap="2">
			{(track.conversation_topic ?? []).map((topic) => (
				<Chip minH={'28px'} bg={'white'} key={topic} text={t(topic)} />
			))}

			{(track.duration || 0) > 0 && (
				<Chip
					icon={<Clock size={20} />}
					minH={'28px'}
					bg={'white'}
					text={`${track.duration ?? ''} min`}
				/>
			)}

			{track.session_xp && track.story_xp && (
				<Chip
					border={0}
					bg="transparent"
					minH={'28px'}
					fontWeight="semibold"
					icon={<XpIcon />}
					text={`${track.session_xp} | ${track.story_xp}`}
				/>
			)}
		</Flex>
	);
};
