import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Sidebar } from './SideBar';
import { useAuthInfo } from '@propelauth/react';
import * as Sentry from '@sentry/react';

type AppLayoutProps = {
	children: React.ReactNode;
};

export const AppLayout = ({ children }: AppLayoutProps) => {
	const { user } = useAuthInfo();

	useEffect(() => {
		if (user) {
			Sentry.setUser({
				email: user.email,
			});
		}
	}, [user]);

	return (
		<Flex as="section" h="100vh">
			<Sidebar />
			<Flex
				flexShrink={1}
				direction={'column'}
				gap="24px"
				w="100%"
				overflowY={'auto'}
				py={{ base: '10px', md: '30px', xl: '80px' }}
				px={{ base: '10px', md: '30px', xl: '80px' }}
			>
				<Box w="100%" mx={'auto'} maxW={'1300px'}>
					{children}
				</Box>
			</Flex>
		</Flex>
	);
};
