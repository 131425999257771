import {
	FormControl,
	FormLabel,
	Input,
	Stack,
	forwardRef,
	useDisclosure,
} from '@chakra-ui/react';
import { z, ZodType } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import PopoverFilter from './PopoverFilter';

type RangeFilterForm = {
	min: number;
	max: number;
};

const RangeFilterSchema: ZodType<RangeFilterForm> = z.object({
	min: z.number(),
	max: z.number(),
});

type RangeFilterProps = {
	onApply: (min: number, max: number) => void;
	onClear: () => void;
	hasValue?: boolean;
};

const TextInput = forwardRef((props, ref) => {
	return (
		<FormControl>
			<FormLabel>{props.label}</FormLabel>
			<Input colorScheme="gray" ref={ref} {...props} />
		</FormControl>
	);
});

const RangeFilter = ({ onApply, hasValue, onClear }: RangeFilterProps) => {
	const { isOpen, onToggle, onClose } = useDisclosure();

	const { register, getValues } = useForm<RangeFilterForm>({
		resolver: zodResolver(RangeFilterSchema),
	});

	return (
		<PopoverFilter
			hasValue={hasValue ?? false}
			onClear={() => onClear()}
			isOpen={isOpen}
			onToggle={onToggle}
			onClose={onClose}
			onApply={() => {
				const { min, max } = getValues();
				onApply(min, max);
			}}
		>
			<Stack>
				<TextInput
					size="sm"
					label="More than"
					{...register('min')}
					type="number"
				/>
				<TextInput
					size="sm"
					label="Less then"
					{...register('max')}
					type="number"
				/>
			</Stack>
		</PopoverFilter>
	);
};

export default RangeFilter;
