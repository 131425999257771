import { useQueryParam, withDefault, NumberParam } from 'use-query-params';

export const useActiveScreen = () => {
	const [activeScreen, setActiveScreen] = useQueryParam<number | null>(
		'activeScreen',
		withDefault(NumberParam, null)
	);

	return { activeScreen, setActiveScreen };
};
