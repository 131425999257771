import {
	Box,
	Button,
	Flex,
	Input,
	SkeletonText,
	Stack,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { Invite } from '@jam/api-types';
import { Plus } from '@phosphor-icons/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../common/components/custom-table/CustomTable';
import { useGetInvitedUsersQuery } from '../../../redux/api/accountApi';
import { AddUserModal } from './AddUserModal';

const LoadingState = () => {
	return (
		<Flex mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} spacing={'5'} />
		</Flex>
	);
};

export const PendingInvites = () => {
	const { data, isLoading } = useGetInvitedUsersQuery();
	const { t } = useTranslation(['settings', 'common']);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [search, setSearch] = useState('');
	const columnHelper = createColumnHelper<Invite>();
	const columns = [
		columnHelper.accessor('invitee_email', {
			header: t('common:email').toString(),
			cell: ({ row }) => (
				<Text fontWeight={'medium'}>{row.original.invitee_email}</Text>
			),
		}),
		columnHelper.accessor('role_in_org', {
			header: t('common:role').toString(),
			cell: ({ row }) => {
				return (
					<Text
						px={'2'}
						py={'1'}
						border={'1px'}
						maxW="100px"
						borderRadius={'10px'}
						color={'#757575'}
						cursor={'not-allowed'}
						borderColor={'#757575'}
					>
						{row.original.role_in_org}
					</Text>
				);
			},
		}),
	];
	const customFilter = [{ id: 'invitee_email', value: search }];

	const userData = data?.invites ?? [];

	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<Stack direction="column" spacing="5">
			<Text fontSize="2xl" fontWeight="semibold">
				{t('pending-invites')}
			</Text>
			<Flex gap="5">
				{userData.length > 0 && (
					<Input
						maxW={'500'}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						borderRadius={'full'}
						placeholder={t('search-by-email') ?? ''}
					/>
				)}
				<Button
					id="add-user-button"
					leftIcon={<Plus size={15} />}
					borderRadius={'full'}
					type="submit"
					px="6"
					bgColor={'brand.500'}
					size="md"
					onClick={() => {
						onOpen();
					}}
				>
					{t('add-user')}
				</Button>
				<AddUserModal isOpen={isOpen} onClose={onClose} />
			</Flex>
			{userData.length > 0 && (
				<CustomTable<Invite>
					data={userData}
					columns={columns}
					customFilters={customFilter}
				/>
			)}
		</Stack>
	);
};
