import {
	Box,
	Flex,
	FlexProps,
	Image,
	Stack,
	useDisclosure,
} from '@chakra-ui/react';
import { NavButton } from '../../features/home/components/Navigation/NavButton';

import { Barbell, List, Lock, X } from '@phosphor-icons/react';
import { useLogoutFunction } from '@propelauth/react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetUserDetailsQuery } from '../../redux/api/userApi';

type IProps = FlexProps;

const MotionBox = motion(Box);

export const Sidebar = ({ ...props }: IProps) => {
	const { t } = useTranslation('home');
	const navigate = useNavigate();
	const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
	const { data } = useGetUserDetailsQuery();
	const logoutFunction = useLogoutFunction();

	const isManagerOrOwner = data
		? data?.role === 'Manager' || data?.role === 'Owner'
		: false;

	return (
		<MotionBox
			bg={isOpen ? 'white' : 'transparent'}
			py={{ base: '6', sm: '8' }}
			px={{ base: '4', sm: '6' }}
			overflow="hidden"
			height="100vh"
			borderRight={isOpen ? '1px' : '0px'}
			borderColor={'#E6E6E6'}
			initial={{ width: '250px' }}
			animate={{ width: isOpen ? '250px' : '100px' }}
			transition={{ duration: 0.3 }}
			flexShrink={0}
			{...props}
		>
			<Stack height={'100%'} justify="space-between" spacing="1" width="full">
				<Stack h="100%" spacing="8">
					<Flex alignItems={'center'} justifyContent={'space-between'}>
						{isOpen && <Image w="35px" h="35px" src="/logo.svg" alt="Logo" />}
						<Box onClick={onToggle} _hover={{ cursor: 'pointer' }}>
							{isOpen && <X size="24" />}
							{!isOpen && <List size="24" />}
						</Box>
					</Flex>
					{isOpen && (
						<Stack h="100%" justifyContent={'space-between'} spacing="4">
							<Stack spacing="1">
								<NavButton
									icon={<Image src="/icons/home.svg" w={4} h={4} />}
									label={t('home')}
									onClick={() => navigate('/users/home')}
								/>
								<NavButton
									icon={<Barbell size={16} />}
									label={t('gym')}
									onClick={() => navigate('/gym')}
								/>
								<NavButton
									icon={<Image src="/icons/database.svg" w={4} h={4} />}
									label={t('history')}
									onClick={() => navigate('/users/history')}
								/>
								{isManagerOrOwner && (
									<NavButton
										icon={<Lock size={20} />}
										disabled
										label={t('dashboard')}
									/>
								)}
								<NavButton
									icon={<Lock size={20} />}
									disabled
									label={t('my-skills')}
								/>
								<NavButton
									icon={<Lock size={20} />}
									disabled
									label={t('real-calls')}
								/>
								<NavButton
									icon={<Lock size={20} />}
									disabled
									label={t('leader-board')}
								/>
							</Stack>
							<Stack spacing="1">
								<NavButton
									icon={<Image src="/icons/sign-out.svg" w={4} h={4} />}
									label={t('logout')}
									onClick={() => void logoutFunction(true)}
								/>
								<NavButton
									label={t('Settings')}
									onClick={() => void navigate('/settings')}
								/>
							</Stack>
						</Stack>
					)}
				</Stack>
			</Stack>
		</MotionBox>
	);
};
