/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, Flex, Stack, Text } from '@chakra-ui/react';
import { TrendType, UserUsage } from '@jam/api-types';
import { createColumnHelper } from '@tanstack/react-table';
import CustomTable from '../../../../../../common/components/custom-table/CustomTable';
import { MultipleSelectFilterFn } from '../../../../../../common/components/custom-table/Filters/CustomFilter';
import SingleSelectFilter from '../../../../../../common/components/custom-table/Filters/SingleSelectFilter';
import { useGetUsageInsightsQuery } from '../../../../../../redux/api/dashboardApi';
import { UserStatus } from '../../../data';
import { DeltaBadge } from '../../DeltaBadge';

export const IconForTrend: Record<TrendType, string> = {
	[TrendType.Up]: '/icons/arrow-up-right.svg',
	[TrendType.Down]: '/icons/arrow-down-right.svg',
	[TrendType.Flat]: '/icons/arrow-right.svg',
};

const ColorForUserStatus: Record<UserStatus, string> = {
	[UserStatus.Active]: 'green',
	[UserStatus.Accepted]: 'yellow',
	[UserStatus.Invited]: 'blue',
};

const columnHelper = createColumnHelper<UserUsage>();
const columns = [
	columnHelper.accessor('firstName', {
		header: 'Name',
		filterFn: MultipleSelectFilterFn<UserUsage>,
		meta: { filterVariant: 'multiple-select', name: 'User' },
		cell: ({ row }) => (
			<Stack alignItems={'center'} direction={'row'}>
				<Avatar size="sm" name={row.original.firstName} />
				<Text>
					{row.original.firstName} {row.original.lastName}
				</Text>
			</Stack>
		),
	}),
	// columnHelper.accessor('status', {
	// 	header: 'Status',
	// 	filterFn: MultipleSelectFilterFn<UsageByUser>,
	// 	meta: { filterVariant: 'multiple-select', name: 'Status' },
	// 	cell: ({ row }) => (
	// 		<Tag colorScheme={ColorForUserStatus[row.original.status]}>
	// 			{row.original.status}
	// 		</Tag>
	// 	),
	// }),
	columnHelper.accessor('totalSessions', {
		meta: { filterVariant: 'range', name: 'Total Sessions' },
		header: 'Total Sessions',
		cell: ({ row }) => <Text>{row.original.totalSessions}</Text>,
	}),
	columnHelper.accessor('totalStories', {
		meta: { filterVariant: 'range', name: 'Total Stories' },
		header: 'Total Stories',
		cell: ({ row }) => <Text>{row.original.totalStories}</Text>,
	}),
	columnHelper.accessor('sessionsInWeek.number', {
		header: 'Sessions This week',
		filterFn: () => true,
		meta: {
			name: 'Sessions This week',
			customFilter: ({ column }) => {
				const now = new Date();
				const currentDay = now.getDay();
				const difference = currentDay - 1 || 7; // if it's Sunday, set difference to 7

				const monday = new Date(now.setDate(now.getDate() - difference));
				monday.setHours(0, 0, 0, 0); // set time to 00:00:00

				const sunday = new Date(monday);
				sunday.setDate(sunday.getDate() + 6);
				sunday.setHours(23, 59, 59, 999); // set time to 23:59:59

				const weekRange = `(${monday.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}-${sunday.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })})`;
				const lastMonday = new Date(monday.setDate(monday.getDate() - 7));
				const lastSunday = new Date(sunday.setDate(sunday.getDate() - 7));

				const lastWeekRange = `(${lastMonday.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}-${lastSunday.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })})`;
				//

				return (
					<SingleSelectFilter
						options={[`This Week ${weekRange}`, `Last Week ${lastWeekRange}`]}
						hasValue={!!column.getFilterValue()}
						onApply={(values) => column.setFilterValue(values)} // reload data coming from API
					/>
				);
			},
		},
		cell: ({ row }) => (
			<DeltaBadge number={row.original.sessionsInWeek.number} delta={0} />
		),
	}),
	columnHelper.accessor('storiesInWeek.number', {
		header: 'Stories This week',
		cell: ({ row }) => (
			<DeltaBadge number={row.original.storiesInWeek.number} delta={0} />
		),
	}),
	// columnHelper.accessor('trend', {
	// 	meta: { filterVariant: 'multiple-select', name: 'Trend' },
	// 	filterFn: MultipleSelectFilterFn<UsageByUser>,
	// 	header: 'Trend',
	// 	cell: ({ row }) => (
	// 		<Image
	// 			color={'green'}
	// 			w="5"
	// 			src={IconForTrend[row.original.trend]}
	// 			alt={row.original.trend}
	// 		/>
	// 	),
	// }),
	columnHelper.accessor('avgRatingForAllJourneySessions', {
		filterFn: (row, _, filter: string) => {
			const value = row.original
				.avgRatingForAllJourneySessions as unknown as number;
			if (filter === '4.0 or more') return value >= 4;
			if (filter === '3.0 to 3.9') return value >= 3 && value < 4;
			if (filter === '2.9 or less') return value < 3;
			return true;
		},
		meta: {
			name: 'Rating',
			customFilter: ({ column }) => (
				<SingleSelectFilter
					options={['4.0 or more', '3.0 to 3.9', '2.9 or less']}
					hasValue={!!column.getFilterValue()}
					onApply={(values) => column.setFilterValue(values)}
				/>
			),
		},
		header: 'Rating',
		cell: ({ row }) => (
			<Text>{row.original.avgRatingForAllJourneySessions}</Text>
		),
	}),
];

export const UsageByUserTable = () => {
	const { data } = useGetUsageInsightsQuery();
	if (!data) return null;
	return (
		<Flex direction={'column'}>
			<Text fontSize={'xl'} fontWeight={'semibold'}>
				Usage per User
			</Text>
			<Text mb="10" fontSize={'sm'} color="gray.500" fontWeight={'medium'}>
				Here you can see the usage of each user in total and in the selected
				week.
			</Text>
			<CustomTable<UserUsage> data={data.userUsages} columns={columns} />
		</Flex>
	);
};
