/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { Box, Input } from '@chakra-ui/react';
import { Column, Row, RowData } from '@tanstack/react-table';
import { useMemo, useEffect, HtmlHTMLAttributes } from 'react';
import MultiSelectFilter from './MultiSelectFilter';
import RangeFilter from './RangeFilter';
import SingleSelectFilter from './SingleSelectFilter';

export type FilterVariant = 'text' | 'range' | 'select' | 'multiple-select';

declare module '@tanstack/react-table' {
	interface ColumnMeta<TData extends RowData, TValue> {
		filterVariant?: FilterVariant;
		name: string;
		hideTitle?: boolean;
		customFilter?: (props: { column: Column<TData, TValue> }) => JSX.Element;
	}
}

export const MultipleSelectFilterFn = <T extends Record<string, any>>(
	row: Row<T>,
	columnId: string,
	filterValue: string[]
) => {
	if (filterValue.length === 0) return true;
	const value = row.original[columnId] as unknown as string;
	return filterValue.some((filter) => filter === value);
};

export const SingleSelectFilterFn = <T extends Record<string, any>>(
	row: Row<T>,
	columnId: string,
	filterValue: string
) => {
	const value = row.original[columnId] as unknown as string;
	return filterValue === value;
};

export const CustomFilter = ({ column }: { column: Column<any, unknown> }) => {
	const { filterVariant } = column.columnDef.meta ?? {};

	const columnFilterValue = column.getFilterValue();

	const sortedUniqueValues = useMemo(
		() =>
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			filterVariant === 'range'
				? []
				: Array.from(column.getFacetedUniqueValues().keys()).sort(),
		[column.getFacetedUniqueValues(), filterVariant]
	) as string[];

	if (filterVariant === 'range') {
		const hasValue = !!columnFilterValue;
		return (
			<RangeFilter
				hasValue={hasValue}
				onClear={() => column.setFilterValue(undefined)}
				onApply={(min, max) => column.setFilterValue([min, max])}
			/>
		);
	}
	if (filterVariant === 'select') {
		const hasValue = !!columnFilterValue;
		return (
			<SingleSelectFilter
				hasValue={hasValue}
				onApply={(value) => column.setFilterValue(value)}
				options={sortedUniqueValues}
			/>
		);
	}
	if (filterVariant === 'multiple-select') {
		const hasValue = !!columnFilterValue;
		return (
			<MultiSelectFilter
				hasValue={hasValue}
				onApply={(value) => column.setFilterValue(value)}
				options={sortedUniqueValues}
			/>
		);
	}
	return <></>;
};
