"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CallSessionSchema = exports.CallSchema = exports.CallMessageSchema = exports.RatingSchema = exports.AnalysisSchema = exports.ItemSchema = exports.FeedbackStatus = exports.ConversationType = exports.AnalysisItemState = exports.SolvedStatus = exports.AnalysisState = void 0;
const zod_1 = require("zod");
var AnalysisState;
(function (AnalysisState) {
    AnalysisState["PENDING"] = "PENDING";
    AnalysisState["PROCESSING"] = "PROCESSING";
    AnalysisState["DONE"] = "DONE";
    AnalysisState["SKIPPED"] = "SKIPPED";
})(AnalysisState || (exports.AnalysisState = AnalysisState = {}));
var SolvedStatus;
(function (SolvedStatus) {
    SolvedStatus["Undetected"] = "undetected";
    SolvedStatus["NotSolved"] = "notSolved";
    SolvedStatus["PartlySolved"] = "partlySolved";
    SolvedStatus["Solved"] = "solved";
})(SolvedStatus || (exports.SolvedStatus = SolvedStatus = {}));
var AnalysisItemState;
(function (AnalysisItemState) {
    AnalysisItemState["PROCESSING"] = "PROCESSING";
    AnalysisItemState["DONE"] = "DONE";
    AnalysisItemState["ERROR"] = "ERROR";
})(AnalysisItemState || (exports.AnalysisItemState = AnalysisItemState = {}));
var ConversationType;
(function (ConversationType) {
    ConversationType["live"] = "live";
    ConversationType["call"] = "call";
})(ConversationType || (exports.ConversationType = ConversationType = {}));
var FeedbackStatus;
(function (FeedbackStatus) {
    FeedbackStatus["notStarted"] = "not_started";
    FeedbackStatus["running"] = "running";
    FeedbackStatus["completed"] = "completed";
    FeedbackStatus["error"] = "error";
})(FeedbackStatus || (exports.FeedbackStatus = FeedbackStatus = {}));
exports.ItemSchema = zod_1.z.object({
    id: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    lastUpdatedAt: zod_1.z.string(),
    deletedAt: zod_1.z.string().optional(),
    name: zod_1.z.string().optional(),
    solved: zod_1.z.nativeEnum(SolvedStatus).optional(),
    explanation: zod_1.z.string().optional(),
    suggestion: zod_1.z.string().optional(),
    conversationItemReferenceId: zod_1.z.string().optional(),
    state: zod_1.z.nativeEnum(AnalysisItemState),
});
exports.AnalysisSchema = zod_1.z.object({
    id: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    lastUpdatedAt: zod_1.z.string(),
    deletedAt: zod_1.z.string().nullable(),
    state: zod_1.z.nativeEnum(AnalysisState),
    items: zod_1.z.array(exports.ItemSchema),
});
exports.RatingSchema = zod_1.z.object({
    id: zod_1.z.string(),
    isActive: zod_1.z.boolean(),
    isArchived: zod_1.z.boolean(),
    createdAt: zod_1.z.string(),
    lastUpdatedAt: zod_1.z.string(),
    score: zod_1.z.number(),
    feedback: zod_1.z.string(),
});
exports.CallMessageSchema = zod_1.z.object({
    id: zod_1.z.string(),
    isActive: zod_1.z.boolean(),
    isArchived: zod_1.z.boolean(),
    createdAt: zod_1.z.string(),
    lastUpdatedAt: zod_1.z.string(),
    role: zod_1.z.string(),
    content: zod_1.z.string(),
    secondsFromStart: zod_1.z.number(),
});
exports.CallSchema = zod_1.z.object({
    id: zod_1.z.string(),
    isActive: zod_1.z.boolean(),
    isArchived: zod_1.z.boolean(),
    createdAt: zod_1.z.string(),
    lastUpdatedAt: zod_1.z.string(),
    vapiCallId: zod_1.z.string(),
    transcript: zod_1.z.string(),
    recordingUrl: zod_1.z.string(),
    summary: zod_1.z.string(),
    endedReason: zod_1.z.string(),
    messages: zod_1.z.array(exports.CallMessageSchema),
});
exports.CallSessionSchema = zod_1.z.object({
    id: zod_1.z.string(),
    isActive: zod_1.z.boolean(),
    isArchived: zod_1.z.boolean(),
    createdAt: zod_1.z.string(),
    lastUpdatedAt: zod_1.z.string(),
    call: exports.CallSchema,
    rating: exports.RatingSchema.nullable(),
    analysis: exports.AnalysisSchema,
    storyReferenceId: zod_1.z.string(),
    contentAllocationId: zod_1.z.string().nullable(),
});
