import { Text, Button, ButtonProps, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface NavButtonProps extends ButtonProps {
	icon?: ReactNode;
	label: string;
}

export const NavButton = (props: NavButtonProps) => {
	const { icon, label, disabled, ...buttonProps } = props;
	return (
		<Button
			cursor={disabled ? 'not-allowed' : 'pointer'}
			variant="tertiary"
			color={disabled ? 'gray.400' : 'gray.800'}
			justifyContent="start"
			{...buttonProps}
		>
			<Flex w="100%" alignItems={'center'} justifyContent={'space-between'}>
				<Text textAlign={'center'} verticalAlign={'center'}>
					{label}
				</Text>
				{icon}
			</Flex>
		</Button>
	);
};
