"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.journeyDTOSchema = exports.trackDTOSchema = exports.storyDTOSchema = exports.TextSnippetSchemaDTO = exports.ExternalLinkSchemaDTO = exports.ClientDocumentSchemaDTO = exports.itemSchemaDTO = exports.situationDTOSchema = exports.personaDTOSchema = exports.promptDTOSchema = exports.circumplexmodelDTOSchema = exports.hintDTOSchema = exports.avatarFileDTOSchema = exports.workplaceDTOSchema = void 0;
const zod_1 = require("zod");
exports.workplaceDTOSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
});
exports.avatarFileDTOSchema = zod_1.z.object({
    id: zod_1.z.string(),
    filename: zod_1.z.string(),
    mimeType: zod_1.z.string(),
    filesize: zod_1.z.number(),
    width: zod_1.z.number(),
    height: zod_1.z.number(),
    createdAt: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
    url: zod_1.z.string(),
});
exports.hintDTOSchema = zod_1.z.object({
    hint: zod_1.z.string(),
    id: zod_1.z.string(),
});
exports.circumplexmodelDTOSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
});
exports.promptDTOSchema = zod_1.z.object({
    quadrant_of_cirumplex_model: exports.circumplexmodelDTOSchema,
});
exports.personaDTOSchema = zod_1.z.object({
    base: zod_1.z.object({
        avatar_file: exports.avatarFileDTOSchema,
        name: zod_1.z.string(),
        role: zod_1.z.string(),
        age: zod_1.z.number(),
        workplace: exports.workplaceDTOSchema,
    }),
    prompt: exports.promptDTOSchema,
    createdAt: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
});
exports.situationDTOSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    description: zod_1.z.string(),
    goal: zod_1.z.string(),
    hints: zod_1.z.array(exports.hintDTOSchema),
    conversation_type: zod_1.z.enum(['live', 'call']),
    createdAt: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
});
exports.itemSchemaDTO = zod_1.z.object({
    id: zod_1.z.string(),
    scorecard_title: zod_1.z.string().optional(),
    updatedAt: zod_1.z.string(),
    createdAt: zod_1.z.string(),
});
exports.ClientDocumentSchemaDTO = zod_1.z.object({
    id: zod_1.z.string(),
    title: zod_1.z.string(),
    description: zod_1.z.string().nullish(),
    signedUrl: zod_1.z.string().nullish(),
    updatedAt: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    url: zod_1.z.string().nullish(),
    filename: zod_1.z.string().nullish(),
    mimeType: zod_1.z.string().nullish(),
    filesize: zod_1.z.number().nullish(),
    width: zod_1.z.number().nullish(),
    height: zod_1.z.number().nullish(),
});
exports.ExternalLinkSchemaDTO = zod_1.z.object({
    id: zod_1.z.string(),
    title: zod_1.z.string(),
    url: zod_1.z.string(),
    description: zod_1.z.string().nullish(),
    updatedAt: zod_1.z.string(),
    createdAt: zod_1.z.string(),
});
exports.TextSnippetSchemaDTO = zod_1.z.object({
    id: zod_1.z.string(),
    language: zod_1.z.enum(['de', 'en']),
    title: zod_1.z.string(),
    description: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
    createdAt: zod_1.z.string(),
});
exports.storyDTOSchema = zod_1.z.object({
    id: zod_1.z.string(),
    tag: zod_1.z.string().optional(),
    title: zod_1.z.string(),
    persona: exports.personaDTOSchema,
    situation: exports.situationDTOSchema,
    items_conversation_feedback: zod_1.z.array(exports.itemSchemaDTO),
    conversation_topic: zod_1.z.array(zod_1.z.string()).default([]),
    duration: zod_1.z.number(),
    session_xp: zod_1.z.number(),
    story_xp: zod_1.z.number(),
    difficulty: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
    additional_material: zod_1.z
        .array(zod_1.z.union([
        zod_1.z.object({
            relationTo: zod_1.z.literal('client-documents'),
            value: zod_1.z.union([zod_1.z.string(), exports.ClientDocumentSchemaDTO]),
        }),
        zod_1.z.object({
            relationTo: zod_1.z.literal('external-links'),
            value: zod_1.z.union([zod_1.z.string(), exports.ExternalLinkSchemaDTO]),
        }),
        zod_1.z.object({
            relationTo: zod_1.z.literal('text-snippets'),
            value: zod_1.z.union([zod_1.z.string(), exports.TextSnippetSchemaDTO]),
        }),
    ]))
        .nullish(),
});
exports.trackDTOSchema = zod_1.z.object({
    title: zod_1.z.string(),
    stories: zod_1.z.array(exports.storyDTOSchema),
    id: zod_1.z.string(),
    conversation_topic: zod_1.z.array(zod_1.z.string()),
    duration: zod_1.z.number(),
    session_xp: zod_1.z.number(),
    story_xp: zod_1.z.number(),
});
exports.journeyDTOSchema = zod_1.z.object({
    id: zod_1.z.string(),
    tracks: zod_1.z.array(exports.trackDTOSchema),
    createdAt: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
});
