import { InvitedUsers, OrganizationUsers } from '@jam/api-types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const api = createApi({
	reducerPath: 'account-api',
	tagTypes: ['organization-users', 'pending-invites'],
	baseQuery: fetchBaseQuery({
		baseUrl: `${
			process.env
				.REACT_APP_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}/api/v1/settings`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getInvitedUsers: builder.query<InvitedUsers, void>({
			providesTags: ['pending-invites'],
			query: () => 'org/pending-invites',
		}),
		getOrganizationUsers: builder.query<OrganizationUsers, void>({
			providesTags: ['organization-users'],
			query: () => 'org/users',
		}),
		removeUser: builder.mutation<void, { userId: string }>({
			invalidatesTags: ['organization-users'],
			query: ({ userId }) => ({
				url: 'remove-user',
				method: 'DELETE',
				body: {
					userId,
				},
			}),
		}),
		disableUser: builder.mutation<void, { userId: string }>({
			invalidatesTags: ['organization-users'],
			query: ({ userId }) => ({
				url: 'disable-user',
				method: 'PATCH',
				body: {
					userId,
				},
			}),
		}),
		enableUser: builder.mutation<void, { userId: string }>({
			invalidatesTags: ['organization-users'],
			query: ({ userId }) => ({
				url: 'enable-user',
				method: 'PATCH',
				body: {
					userId,
				},
			}),
		}),
		changeUserRole: builder.mutation<void, { userId: string; role: string }>({
			invalidatesTags: ['organization-users'],
			query: ({ userId, role }) => ({
				url: 'change-role',
				method: 'PATCH',
				body: {
					userId,
					role,
				},
			}),
			async onQueryStarted({ userId, role }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					api.util.updateQueryData(
						'getOrganizationUsers',
						undefined,
						(draft) => {
							const user = draft.users.find((x) => x.userId === userId);
							if (user) {
								user.roleInOrg = role;
							}
						}
					)
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
		inviteUserToOrganization: builder.mutation<
			void,
			{ email: string; role: string }
		>({
			invalidatesTags: ['pending-invites'],
			query: ({ email, role }) => ({
				url: 'invite-user',
				method: 'POST',
				body: {
					email,
					role,
				},
			}),
		}),
		changeAccountInfo: builder.mutation<
			void,
			{
				firstName: string;
				lastName: string;
			}
		>({
			query: (body) => ({
				url: 'change-name',
				method: 'PATCH',
				body,
			}),
		}),
		changeAccountEmail: builder.mutation<
			void,
			{
				newEmail: string;
			}
		>({
			query: (body) => ({
				url: 'change-email',
				method: 'PATCH',
				body,
			}),
		}),
		changePassword: builder.mutation<
			void,
			{
				password: string;
			}
		>({
			query: (body) => ({
				url: 'change-password',
				method: 'PATCH',
				body,
			}),
		}),
	}),
});

export const {
	useGetInvitedUsersQuery,
	useChangePasswordMutation,
	useChangeAccountInfoMutation,
	useChangeAccountEmailMutation,
	useGetOrganizationUsersQuery,
	useInviteUserToOrganizationMutation,
	useChangeUserRoleMutation,
	useRemoveUserMutation,
	useDisableUserMutation,
	useEnableUserMutation,
} = api;
export default api;
