import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	Stack,
	Button,
	PopoverFooter,
} from '@chakra-ui/react';
import { FunnelSimple } from '@phosphor-icons/react';

type PopoverFilterProps = {
	isOpen: boolean;
	onToggle: () => void;
	onClose: () => void;
	onApply: () => void;
	onClear: () => void;
	hasValue: boolean;
	children: React.ReactNode;
};

const PopoverFilter = ({
	isOpen,
	onToggle,
	onClose,
	hasValue,
	onApply,
	onClear,
	children,
}: PopoverFilterProps) => {
	let color = hasValue ? 'red' : 'gray';
	if (isOpen) {
		color = 'blue';
	}
	return (
		<Popover isOpen={isOpen} onClose={onClose}>
			<PopoverTrigger>
				<FunnelSimple onClick={onToggle} size={20} color={color} />
			</PopoverTrigger>
			<PopoverContent>
				<PopoverBody py="4" maxHeight={'200px'} overflowY={'auto'}>
					{children}
				</PopoverBody>
				<PopoverFooter>
					<Stack w="fit-content" ml="auto" direction={'row'}>
						<Button
							colorScheme="purple"
							variant={'outline'}
							size="xs"
							onClick={() => {
								onApply();
								onClose();
							}}
						>
							Apply
						</Button>
						<Button
							colorScheme="red"
							variant={'ghost'}
							size="xs"
							onClick={() => {
								onClear();
								onClose();
							}}
						>
							Clear
						</Button>
					</Stack>
				</PopoverFooter>
			</PopoverContent>
		</Popover>
	);
};

export default PopoverFilter;
