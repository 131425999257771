export interface AuthInfo {
	loading: boolean;
	accessToken: string;
	isLoggedIn: true;
	orgHelper: OrgHelper;
	accessHelper: AccessHelper;
	user: User;
	impersonatorUserId?: string;
	isImpersonating: boolean;
	accessTokenExpiresAtSeconds: number;
	refreshAuthInfo: () => Promise<void>;
}

interface User {
	userId: string;
	email: string;
	emailConfirmed: boolean;
	hasPassword: boolean;
	username?: string;
	firstName?: string;
	lastName?: string;
	pictureUrl?: string;
	locked: boolean;
	enabled: boolean;
	mfaEnabled: boolean;
	canCreateOrgs: boolean;
	createdAt: number;
	lastActiveAt: number;
	legacyUserId?: string;
	properties?: {
		[key: string]: unknown;
	};
}
// export type AuthenticationInfo = {
// 	accessToken: string;
// 	expiresAtSeconds: number;
// 	orgHelper: OrgHelper;
// 	accessHelper: AccessHelper;
// 	orgIdToOrgMemberInfo?: OrgIdToOrgMemberInfo;
// 	user: User;
// 	userClass: UserClass;
// 	impersonatorUserId?: string;
// };

export enum UserRole {
	Admin = 'Admin',
	Owner = 'Owner',
	Manager = 'Manager',
	Member = 'Member',
}
interface OrgMemberInfo {
	orgId: string;
	orgName: string;
	urlSafeOrgName: string;
	userAssignedRole: string;
	userInheritedRolesPlusCurrentRole: string[];
	userPermissions: string[];
}

interface OrgHelper {
	// returns all orgs that the user is a member of
	getOrgs: () => OrgMemberInfo[];
	// returns all org ids that the user is a member of
	getOrgIds: () => string[];
	// returns org information for a given orgId
	getOrg: (orgId: string) => OrgMemberInfo | undefined;
	// returns org information for a given org by name
	getOrgByName: (orgName: string) => OrgMemberInfo | undefined;
}

interface AccessHelper {
	isRole: (orgId: string, role: string) => boolean;
	isAtLeastRole: (orgId: string, role: string) => boolean;
	hasPermission: (orgId: string, permission: string) => boolean;
	hasAllPermissions: (orgId: string, permissions: string[]) => boolean;
	getAccessHelperWithOrgId: (orgId: string) => AccessHelperWithOrg;
}
interface AccessHelperWithOrg {
	isRole: (role: string) => boolean;
	isAtLeastRole: (role: string) => boolean;
	hasPermission: (permission: string) => boolean;
	hasAllPermissions: (permissions: string[]) => boolean;
}
