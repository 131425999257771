import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

interface MemoizedOutletProps {
	accessToken: string;
}

export const MemoizedOutlet = memo<MemoizedOutletProps>(
	({ accessToken }: MemoizedOutletProps) => (
		<QueryParamProvider adapter={ReactRouter6Adapter}>
			<Outlet />
		</QueryParamProvider>
	),
	(prevProps, nextProps) => prevProps.accessToken === nextProps.accessToken
);

MemoizedOutlet.displayName = 'MemoizedOutlet';
