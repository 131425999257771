import {
	Checkbox,
	CheckboxGroup,
	Stack,
	useDisclosure,
} from '@chakra-ui/react';
import { z, ZodType } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import PopoverFilter from './PopoverFilter';

type MultiSelectForm = {
	values: string[];
};

const MultiSelectSchema: ZodType<MultiSelectForm> = z.object({
	values: z.array(z.string()),
});

type MultiSelectFilterProps = {
	options: string[];
	hasValue?: boolean;
	onApply: (value: string[] | undefined) => void;
};

const MultiSelectFilter = ({
	options,
	onApply,
	hasValue,
}: MultiSelectFilterProps) => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	const { control, getValues } = useForm<MultiSelectForm>({
		resolver: zodResolver(MultiSelectSchema),
	});
	return (
		<PopoverFilter
			hasValue={hasValue ?? false}
			onClear={() => onApply(undefined)}
			isOpen={isOpen}
			onToggle={onToggle}
			onClose={onClose}
			onApply={() => {
				const { values } = getValues();
				onApply(values);
			}}
		>
			<Controller
				control={control}
				name="values"
				render={({ field }) => (
					<CheckboxGroup colorScheme="green" {...field}>
						<Stack spacing={3} direction={['column']}>
							{options.map((opt) => {
								return (
									<Checkbox key={opt} value={opt}>
										{opt}
									</Checkbox>
								);
							})}
						</Stack>
					</CheckboxGroup>
				)}
			/>
		</PopoverFilter>
	);
};

export default MultiSelectFilter;
