import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const Chip = ({
	text,
	icon,
	...props
}: { text: string; icon?: ReactNode } & FlexProps) => {
	return (
		<Flex
			w="fit-content"
			px="2"
			gap="2"
			alignItems={'center'}
			border={'1px'}
			borderColor={'#D9D9D9'}
			borderRadius={'2xl'}
			h="fit-content"
			bg="#F5F5F5"
			{...props}
		>
			{icon && icon}
			<Text whiteSpace="nowrap" wordBreak="keep-all" fontSize={'12px'}>
				{text}
			</Text>
		</Flex>
	);
};
