import { TrendType } from '@jam/api-types';

interface UsageByWeek {
	name: string;
	storiesPerUser: number;
	sessionsPerUser: number;
}

interface TrainingPerformance {
	name: string;
	performance: number;
}

interface TeamPerformance {
	week: string;
	firstSessionPerformance: number;
	secondSessionPerformance: number;
}

export enum UserStatus {
	Active = 'Active',
	Accepted = 'Accepted',
	Invited = 'Invited',
}

export interface UsageByUser {
	name: string;
	status: UserStatus;
	totalStories: number;
	totalSessions: number;
	trend: TrendType;
	rating: number;
	sessionsInWeek: {
		number: number;
		delta: number;
	};
	storiesInWeek: {
		number: number;
		delta: number;
	};
}

export interface TrainingPerformanceDetails {
	name: string;
	description: string;
	firstSessionSuccessRate: number;
	secondSessionSuccessRate: number;
	completionRate: number;
	repetitionRate: number;
	rating: number;
	desiredQuotes: string;
	unDesiredQuotes: string;
	mostSuccessfulAnswers: string;
	keyChallenges: string;
}

export const TeamPerformanceData: TeamPerformance[] = [
	{
		week: 'Week 1',
		firstSessionPerformance: 44,
		secondSessionPerformance: 77,
	},
	{
		week: 'Week 2',
		firstSessionPerformance: 55,
		secondSessionPerformance: 88,
	},
	{
		week: 'Week 3',
		firstSessionPerformance: 66,
		secondSessionPerformance: 99,
	},
	{
		week: 'Week 4',
		firstSessionPerformance: 33,
		secondSessionPerformance: 55,
	},
];

export const UsageByWeekData: UsageByWeek[] = [
	{
		name: 'Week 1',
		storiesPerUser: 11,
		sessionsPerUser: 35,
	},
	{
		name: 'week 2',
		storiesPerUser: 7,
		sessionsPerUser: 33,
	},
	{
		name: 'Week 3',
		storiesPerUser: 66,
		sessionsPerUser: 67,
	},
];

export const trainingPerformanceData: TrainingPerformance[] = [
	{
		name: 'First Session',
		performance: 44,
	},
	{
		name: 'Second Session',
		performance: 77,
	},
];

export const usageByUsers: UsageByUser[] = [
	{
		name: 'Anxhelo',
		status: UserStatus.Accepted,
		totalStories: 10,
		totalSessions: 20,
		trend: TrendType.Up,
		rating: 4.5,
		sessionsInWeek: {
			number: 5,
			delta: 1,
		},
		storiesInWeek: {
			number: 2,
			delta: -1,
		},
	},
	{
		name: 'Niko',
		status: UserStatus.Invited,
		totalStories: 5,
		totalSessions: 10,
		trend: TrendType.Flat,
		rating: 3.5,
		sessionsInWeek: {
			number: 2,
			delta: -1,
		},
		storiesInWeek: {
			number: 1,
			delta: 0,
		},
	},
	{
		name: 'Tobias',
		status: UserStatus.Active,
		totalStories: 15,
		totalSessions: 30,
		trend: TrendType.Up,
		rating: 4.7,
		sessionsInWeek: {
			number: 6,
			delta: 2,
		},
		storiesInWeek: {
			number: 3,
			delta: 1,
		},
	},
	{
		name: 'Sebastian',
		status: UserStatus.Active,
		totalStories: 7,
		totalSessions: 14,
		trend: TrendType.Down,
		rating: 3.2,
		sessionsInWeek: {
			number: 3,
			delta: -2,
		},
		storiesInWeek: {
			number: 1,
			delta: -1,
		},
	},
];

export const trainingPerformanceDetailsData: TrainingPerformanceDetails[] = [
	{
		name: 'Session 1',
		description: 'First training session',
		firstSessionSuccessRate: 80,
		secondSessionSuccessRate: 0,
		completionRate: 100,
		repetitionRate: 20,
		rating: 4.5,
		desiredQuotes: 'Great session!',
		unDesiredQuotes: 'Could be better.',
		mostSuccessfulAnswers: 'Answer 1',
		keyChallenges: 'Challenge 1',
	},
	{
		name: 'Session 2',
		description: 'Second training session',
		firstSessionSuccessRate: 0,
		secondSessionSuccessRate: 85,
		completionRate: 100,
		repetitionRate: 15,
		rating: 4.7,
		desiredQuotes: 'Excellent session!',
		unDesiredQuotes: 'A bit difficult.',
		mostSuccessfulAnswers: 'Answer 2',
		keyChallenges: 'Challenge 2',
	},
	{
		name: 'Session 3',
		description: 'Third training session',
		firstSessionSuccessRate: 75,
		secondSessionSuccessRate: 0,
		completionRate: 100,
		repetitionRate: 25,
		rating: 4.6,
		desiredQuotes: 'Very good session!',
		unDesiredQuotes: 'A bit easy.',
		mostSuccessfulAnswers: 'Answer 3',
		keyChallenges: 'Challenge 3',
	},
	{
		name: 'Session 4',
		description: 'Fourth training session',
		firstSessionSuccessRate: 0,
		secondSessionSuccessRate: 90,
		completionRate: 100,
		repetitionRate: 10,
		rating: 4.8,
		desiredQuotes: 'Perfect session!',
		unDesiredQuotes: 'Nothing to improve.',
		mostSuccessfulAnswers: 'Answer 4',
		keyChallenges: 'Challenge 4',
	},
];
