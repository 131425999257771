import { UserDetails } from '@jam/api-types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import cmsApi from './cmsApi';

const api = createApi({
	reducerPath: 'user-api',
	baseQuery: fetchBaseQuery({
		baseUrl: `${
			process.env
				.REACT_APP_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}/api/v1`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getUserDetails: builder.query<UserDetails, void>({
			query: () => 'users/whoami',
		}),
		updateUserDetails: builder.mutation<void, string>({
			query: (language: string) => ({
				url: `users/language/set/${language}`,
				method: 'PATCH',
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				// Clear CmsApi state to get new language content
				dispatch(cmsApi.util.resetApiState());
			},
		}),
	}),
});

export const {
	useLazyGetUserDetailsQuery,
	useUpdateUserDetailsMutation,
	useGetUserDetailsQuery,
} = api;
export default api;
