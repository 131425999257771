import { Avatar, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { TeamPerformanceInsights, UserPerformance } from '@jam/api-types';
import { createColumnHelper } from '@tanstack/react-table';
import CustomTable from '../../../../../../common/components/custom-table/CustomTable';
import { MultipleSelectFilterFn } from '../../../../../../common/components/custom-table/Filters/CustomFilter';
import { IconForTrend } from '../UsageScreen/UsageByUserTable';

type TeamPerformanceProps = {
	data: TeamPerformanceInsights['userPerformances'];
};

const columnHelper = createColumnHelper<UserPerformance>();

const columns = [
	columnHelper.accessor('firstName', {
		header: 'Name',
		filterFn: MultipleSelectFilterFn<UserPerformance>,
		meta: { filterVariant: 'multiple-select', name: 'User' },
		cell: ({ row }) => (
			<Stack alignItems={'center'} direction={'row'}>
				<Avatar size="sm" name={row.original.firstName} />
				<Text>
					{row.original.firstName} {row.original.lastName}
				</Text>
			</Stack>
		),
	}),
	columnHelper.accessor('averageFirstSessionScore', {
		meta: { filterVariant: 'range', name: 'Total Sessions' },
		header: 'Average First Session Score',
		cell: ({ row }) => <Text>{row.original.averageFirstSessionScore}</Text>,
	}),
	columnHelper.accessor('averageSecondSessionScore', {
		meta: { filterVariant: 'range', name: 'Total Sessions' },
		header: 'Average Second Session Score',
		cell: ({ row }) => (
			<Text>{row.original.averageSecondSessionScore ?? 0}</Text>
		),
	}),
	columnHelper.accessor('averageImprovement', {
		meta: { filterVariant: 'range', name: 'Total Sessions' },
		header: 'Average Performance Improvement',
		cell: ({ row }) => <Text>{row.original.averageImprovement ?? 0}</Text>,
	}),
	columnHelper.accessor('averagePerformanceTrend', {
		meta: { filterVariant: 'multiple-select', name: 'Trend' },
		filterFn: MultipleSelectFilterFn<UserPerformance>,
		header: 'Trend',
		cell: ({ row }) => (
			<Image
				color={'green'}
				w="5"
				src={IconForTrend[row.original.averagePerformanceTrend]}
				alt={row.original.averagePerformanceTrend}
			/>
		),
	}),
];

export const TeamPerformanceTable = ({ data }: TeamPerformanceProps) => {
	return (
		<Flex direction={'column'}>
			<Text fontSize={'xl'} fontWeight={'semibold'}>
				Team Performance
			</Text>
			<Text mb="10" fontSize={'sm'} color="gray.500" fontWeight={'medium'}>
				Performance of team members
			</Text>
			<CustomTable<UserPerformance> data={data} columns={columns} />
		</Flex>
	);
};
