import { Box, Flex, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { LockKey } from '@phosphor-icons/react/dist/ssr';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../redux/slice';

type InspiringNews = {
	type: string;
	title: string;
	subtitle?: string;
	tag: string;
	image: string;
};

const inspiringNewsListDe: InspiringNews[] = [
	{
		type: 'Inspiration',
		title: 'Die Kunst der Überzeugung: Transformieren Sie Ihren Verkaufsansatz',
		subtitle: 'Wie Sie mit Persuasionstechniken mehr Abschlüsse machen',
		tag: 'Forbes, 2024',
		image: '/images/ai2.jpg',
	},
	{
		type: 'Inspiration',
		title:
			'Resilienz im Vertrieb: Nach verlorenen Deals die Motivation wiederfinden',
		subtitle: 'Wie man ein "Nein" in eine Chance verwandelt',
		tag: 'Harvard Business Review, 2024',
		image: '/images/ai.jpg',
	},
	{
		type: 'Inspiration',
		title: 'Empathie verkauft mehr: Echte Verbindungen mit Kunden aufbauen',
		subtitle: 'Das Geheimnis zur Förderung von Vertrauen und Loyalität',
		tag: 'Inc., 2024',
		image: '/images/ai.jpg',
	},
	{
		type: 'Inspiration',
		title: 'Erfolgreich durch Daten: Analytik für Wachstum im Vertrieb nutzen',
		subtitle:
			'Steigern Sie Ihre Abschlussrate mithilfe von praxisrelevanten Insights',
		tag: 'MIT Sloan Management Review, 2024',
		image: '/images/ai2.jpg',
	},
	{
		type: 'Inspiration',
		title: 'Storytelling im Vertrieb: Ihr Publikum fesseln',
		subtitle: 'Kunden durch fesselnde Erzählungen begeistern',
		tag: 'Entrepreneur, 2024',
		image: '/images/ai2.jpg',
	},
];

const inspiringNewsListEn: InspiringNews[] = [
	{
		type: 'Inspiration',
		title: 'The Art of Persuasion: Transform Your Sales Approach',
		subtitle: 'Close more deals by mastering the power of influence',
		tag: 'Forbes, 2024',
		image: '/images/ai2.jpg',
	},
	{
		type: 'Inspiration',
		title: 'Resilience in Sales: Bouncing Back from Rejection',
		subtitle: 'How to turn "no" into opportunity',
		tag: 'Harvard Business Review, 2024',
		image: '/images/ai.jpg',
	},
	{
		type: 'Inspiration',
		title: 'Empathy Sells: Building Genuine Connections with Clients',
		subtitle: 'The secret to fostering trust and loyalty',
		tag: 'Inc., 2024',
		image: '/images/ai.jpg',
	},
	{
		type: 'Inspiration',
		title: 'Data-Driven Success: Leveraging Analytics for Sales Growth',
		subtitle: 'Boosting your close rate with actionable insights',
		tag: 'MIT Sloan Management Review, 2024',
		image: '/images/ai2.jpg',
	},
	{
		type: 'Inspiration',
		title: 'Storytelling in Sales: Captivating Your Audience',
		subtitle: 'Engaging customers through compelling narratives',
		tag: 'Entrepreneur, 2024',
		image: '/images/ai2.jpg',
	},
];

export const GetInspired = () => {
	const { t } = useTranslation('home');
	const language = useSelector(selectLanguage);
	const inspiringNews =
		language === 'en' ? inspiringNewsListEn : inspiringNewsListDe;
	return (
		<Box mt="4" p="3" bg="gray.50" cursor={'not-allowed'}>
			<Flex mb="6" alignItems={'center'} gap="2">
				<Text
					w="fit-content"
					fontSize="2xl"
					color={'#757575'}
					fontWeight="semibold"
				>
					{t('get_inspired')}
				</Text>
				<LockKey color={'#757575'} fontWeight="500" size={30} />
			</Flex>
			<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
				{inspiringNews.map((news) => (
					<Flex
						border={'1px'}
						borderColor={'gray.200'}
						borderRadius={'2xl'}
						key={news.title}
					>
						<Flex
							direction={'column'}
							justifyContent={'space-between'}
							width={'50%'}
							borderRadius="md"
							p={4}
						>
							<Box>
								<Text fontSize="16px" color="gray.500">
									{news.type}
								</Text>
								<Text mt="1" fontSize="18px" fontWeight="semibold">
									{news.title}
								</Text>
								<Text mt="2" fontSize="14px" color="gray.500">
									{news.subtitle}
								</Text>
							</Box>
							<Text fontSize="sm" color="gray.500">
								{news.tag}
							</Text>
						</Flex>
						<Image
							width={'50%'}
							borderRightRadius={'2xl'}
							src={news.image}
							alt={news.title}
							objectFit="cover"
							filter={'blur(1.5px) brightness(0.8);'}
							cursor="pointer"
						/>
					</Flex>
				))}
			</SimpleGrid>
		</Box>
	);
};
