"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagerStoryFeedbackSchema = exports.LearnerStoryItemFeedbackSchema = exports.LearnerStoryFeedbackSchema = exports.UserFeedbackSchema = exports.ManagerStoryFeedbackReferenceSchema = exports.UserFeedbackTypeEnum = exports.ManagerStoryFeedbackEntityReferenceEnum = exports.LearnerStoryItemPresetEnum = void 0;
const zod_1 = require("zod");
// Enums
exports.LearnerStoryItemPresetEnum = zod_1.z.enum([
    'NOT_FACTUALLY_CORRECT',
    'DONT_UNDERSTAND',
    'GRAMMAR_MISTAKE_OR_SPELLING_ERROR',
    'TOO_COMPLICATED',
]);
exports.ManagerStoryFeedbackEntityReferenceEnum = zod_1.z.enum([
    'ITEM',
    'PERSONA',
    'SCENARIO',
    'GOAL',
]);
exports.UserFeedbackTypeEnum = zod_1.z.enum([
    'LEARNER_STORY',
    'LEARNER_STORY_ITEM',
    'MANAGER_STORY',
]);
// Schemas
exports.ManagerStoryFeedbackReferenceSchema = zod_1.z.object({
    referenceType: exports.ManagerStoryFeedbackEntityReferenceEnum,
    referenceId: zod_1.z.string(),
});
exports.UserFeedbackSchema = zod_1.z.object({
    id: zod_1.z.string(),
    createdAt: zod_1.z.date(),
    lastUpdatedAt: zod_1.z.date(),
    deletedAt: zod_1.z.date().optional().nullable(),
    userId: zod_1.z.string(),
    content: zod_1.z.string().optional().nullable(),
});
exports.LearnerStoryFeedbackSchema = exports.UserFeedbackSchema.extend({
    storyReferenceId: zod_1.z.string(),
    rating: zod_1.z.number(),
});
exports.LearnerStoryItemFeedbackSchema = exports.UserFeedbackSchema.extend({
    sessionId: zod_1.z.string(),
    storyItemReferenceId: zod_1.z.string(),
    positive: zod_1.z.boolean(),
    preset: zod_1.z.array(exports.LearnerStoryItemPresetEnum),
});
exports.ManagerStoryFeedbackSchema = exports.UserFeedbackSchema.extend({
    storyReferenceId: zod_1.z.string(),
    reference: exports.ManagerStoryFeedbackReferenceSchema.optional().nullable(),
});
