import {
	Flex,
	Text,
	FormControl,
	FormLabel,
	Stack,
	Input,
	Button,
	useToast,
	FormErrorMessage,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z, ZodType } from 'zod';
import {
	useChangeAccountEmailMutation,
	useChangeAccountInfoMutation,
} from '../../../redux/api/accountApi';
import { useTranslation } from 'react-i18next';

type AccountInfo = {
	firstName?: string;
	lastName?: string;
	email: string;
};

type AccountDataFormProps = AccountInfo & {
	pictureUrl?: string;
};

const AccountInfoSchema: ZodType<AccountInfo> = z.object({
	firstName: z.string().min(2, 'First name must be at least 2 characters long'),
	lastName: z.string().min(2, 'Last name must be at least 2 characters long'),
	email: z.string().email('Invalid email format').min(1),
});

export const AccountDataForm = (props: AccountDataFormProps) => {
	const { t } = useTranslation('settings');
	const [changeAccountInfo] = useChangeAccountInfoMutation();
	const [changeAccountEmail] = useChangeAccountEmailMutation();
	const toast = useToast();

	const {
		register,
		handleSubmit,
		reset,
		formState: { isDirty, errors, isSubmitting },
	} = useForm<AccountInfo>({
		resolver: zodResolver(AccountInfoSchema),
		defaultValues: props,
	});

	const onSubmit = async (data: AccountInfo) => {
		await changeAccountInfo({
			firstName: data.firstName ?? '',
			lastName: data.lastName ?? '',
		});
		await changeAccountEmail({
			newEmail: data.email,
		});

		toast({
			title: t('account_info_updated'),
			status: 'success',
			duration: 3000,
			isClosable: true,
		});
	};

	return (
		<Flex direction={'column'}>
			<Text fontWeight={'medium'} fontSize={'24px'} color={'#757575'}>
				{t('account-title')}
			</Text>
			<Text mt="3" fontWeight={'light'}>
				{t('account-description')}
			</Text>
			{/* <Flex alignItems={'center'} gap="5" mt="5">
				<Avatar
					size={'xl'}
					src={props.pictureUrl ?? ''}
					name={props.firstName ?? ''}
					bg="grey"
				/>
				<Text cursor={'pointer'} textDecoration={'underline'} color="#757575">
					Edit
				</Text>
			</Flex> */}
			<Stack mt="8" spacing={4} maxW={'500px'}>
				<FormControl isInvalid={!!errors.firstName}>
					<FormLabel>{t('first-name')}</FormLabel>
					<Input borderRadius={'full'} {...register('firstName')} />
					{errors.firstName?.message && (
						<FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
					)}
				</FormControl>
				<FormControl isInvalid={!!errors.lastName}>
					<FormLabel>{t('last-name')}</FormLabel>
					<Input borderRadius={'full'} {...register('lastName')} />
					{errors.lastName?.message && (
						<FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
					)}
				</FormControl>
				<FormControl isInvalid={!!errors.email}>
					<FormLabel>{t('email-address')}</FormLabel>
					<Input borderRadius={'full'} {...register('email')} />
					{errors.email?.message && (
						<FormErrorMessage>{errors.email.message}</FormErrorMessage>
					)}
				</FormControl>
				<Flex mt="4" gap="5">
					<Button
						id="save-user-info-button"
						borderRadius={'full'}
						type="submit"
						isLoading={isSubmitting}
						px="6"
						bgColor={isDirty ? 'brand.500' : 'gray.500'}
						size="md"
						onClick={() => {
							if (!isDirty) return;
							void handleSubmit(onSubmit)();
						}}
					>
						{t('save')}
					</Button>
					<Button
						type="reset"
						color={'black'}
						bg="transparent"
						colorScheme="red"
						size="md"
						onClick={() => {
							reset();
						}}
					>
						{t('cancel')}
					</Button>
				</Flex>
			</Stack>
		</Flex>
	);
};
