"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyContentAllocationDetailsSchema = exports.JourneyContentAllocationStatusSchema = exports.TrackStatusSchema = exports.JourneyContentAllocationSchema = exports.RefresherSchema = void 0;
const zod_1 = require("zod");
exports.RefresherSchema = zod_1.z.object({
    id: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    lastUpdatedAt: zod_1.z.string(),
    deletedAt: zod_1.z.nullable(zod_1.z.string()),
    userId: zod_1.z.string(),
    storyReferenceId: zod_1.z.string(),
    skipped: zod_1.z.boolean(),
});
exports.JourneyContentAllocationSchema = zod_1.z.object({
    id: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    lastUpdatedAt: zod_1.z.string(),
    deletedAt: zod_1.z.string(),
    userId: zod_1.z.string(),
    journeyReferenceId: zod_1.z.string(),
});
exports.TrackStatusSchema = zod_1.z.object({
    trackId: zod_1.z.string(),
    hasFinished: zod_1.z.boolean(),
    completedStoryIds: zod_1.z.array(zod_1.z.string()),
});
exports.JourneyContentAllocationStatusSchema = zod_1.z.object({
    trackStatuses: zod_1.z.array(exports.TrackStatusSchema),
    nextActiveStoryId: zod_1.z.string(),
});
exports.JourneyContentAllocationDetailsSchema = exports.JourneyContentAllocationSchema.merge(exports.JourneyContentAllocationStatusSchema);
