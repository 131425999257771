import { useTranslation } from 'react-i18next';
import { AppLayout } from '../common/components/AppLayout';
import { Salutation } from '../features/home/components/Salutation';
import { Flex } from '@chakra-ui/react';
import { CurrentTrackDetails } from '../features/gym/components/CurrentTrackDetails';
import { AllStories } from '../features/gym/components/AllStories';
import { AllTracks } from '../features/gym/components/AllTracks';

export const GymPage = () => {
	const { t } = useTranslation('gym');
	return (
		<AppLayout>
			<Salutation text={t('title')} />
			<Flex my="10" direction={{ base: 'column' }} gap="5">
				<CurrentTrackDetails />
				<AllStories />
				<AllTracks />
			</Flex>
		</AppLayout>
	);
};
