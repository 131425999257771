import {
	Box,
	Flex,
	Link,
	Stack,
	Stat,
	StatArrow,
	StatHelpText,
	StatNumber,
} from '@chakra-ui/react';
import { UsageByWeekChart } from '../UsageScreen/UsageByWeekChart';
import { TrainingPerformanceChart } from '../TrainingPerformance/TrainingPerformanceChart';
import { TeamPerformanceChart } from '../TeamPerformance/TeamPerformanceChart';
import { useActiveScreen } from '../../../../hooks/useActiveScreen';

export const OverviewScreen = () => {
	const { setActiveScreen } = useActiveScreen();
	return (
		<Stack direction={'column'} spacing={'10'}>
			<Flex mt="10" justifyContent={'center'}>
				<Box>
					<Stat>
						<StatNumber fontSize={'4xl'}>
							1,512 training sessions completed
						</StatNumber>
						<StatHelpText>
							<StatArrow type="increase" />
							38 last 7 days
						</StatHelpText>
					</Stat>
				</Box>
			</Flex>
			<Flex bg="gray.50" flexWrap={'wrap'}>
				<Stack direction={'column'} spacing={'2'}>
					<UsageByWeekChart width={450} showLearnMore />
				</Stack>
				<Stack direction={'column'} spacing={'2'}>
					<TrainingPerformanceChart showLearnMore />
				</Stack>
				<Stack direction={'column'} spacing={'2'}>
					<TeamPerformanceChart showLearnMore />
				</Stack>
			</Flex>
		</Stack>
	);
};
