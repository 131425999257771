"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserDetailsSchema = exports.OrganizationSchema = exports.InvitedUsersSchema = exports.InviteSchema = exports.OrganizationPersonSchema = exports.OrganizationUsersSchema = exports.OrganizationUserSchema = void 0;
const zod_1 = require("zod");
const common_1 = require("./common");
const UserRoleSchema = zod_1.z.enum(['Owner', 'Manager', 'Member']);
exports.OrganizationUserSchema = zod_1.z.object({
    userId: zod_1.z.string(),
    roleInOrg: zod_1.z.string(),
    email: zod_1.z.string(),
    emailConfirmed: zod_1.z.boolean(),
    hasPassword: zod_1.z.boolean(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    pictureUrl: zod_1.z.string(),
    locked: zod_1.z.boolean(),
    enabled: zod_1.z.boolean(),
    mfaEnabled: zod_1.z.boolean(),
    canCreateOrgs: zod_1.z.boolean(),
    createdAt: zod_1.z.number(),
});
exports.OrganizationUsersSchema = zod_1.z.object({
    users: zod_1.z.array(exports.OrganizationUserSchema),
    total_users: zod_1.z.number(),
    current_page: zod_1.z.number(),
    page_size: zod_1.z.number(),
    has_more_results: zod_1.z.boolean(),
});
exports.OrganizationPersonSchema = zod_1.z.object({
    userId: zod_1.z.string(),
    email: zod_1.z.string(),
    role: zod_1.z.string(),
    status: zod_1.z.string(),
    createdAt: zod_1.z.number(),
});
exports.InviteSchema = zod_1.z.object({
    invitee_email: zod_1.z.string(),
    org_id: zod_1.z.string(),
    org_name: zod_1.z.string(),
    role_in_org: zod_1.z.string(),
    created_at: zod_1.z.number(),
    expires_at: zod_1.z.number(),
});
exports.InvitedUsersSchema = zod_1.z.object({
    total_invites: zod_1.z.number(),
    current_page: zod_1.z.number(),
    page_size: zod_1.z.number(),
    has_more_results: zod_1.z.boolean(),
    invites: zod_1.z.array(exports.InviteSchema),
});
exports.OrganizationSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string(),
    journeyIds: zod_1.z.array(zod_1.z.string()),
    storyIds: zod_1.z.array(zod_1.z.string()),
});
exports.UserDetailsSchema = zod_1.z.object({
    id: zod_1.z.string(),
    email: zod_1.z.string().nullable().optional(),
    firstName: zod_1.z.string().nullable().optional(),
    lastName: zod_1.z.string().nullable().optional(),
    pictureUrl: zod_1.z.string().nullable().optional(),
    language: common_1.LanguageSchema,
    role: UserRoleSchema,
    organization: exports.OrganizationSchema,
});
