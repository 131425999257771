import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyGetUserDetailsQuery } from '../../redux/api/userApi';
import { setAccessToken, setLanguage } from '../../redux/slice';
import { useAuthInfo } from '@propelauth/react';
import { MemoizedOutlet } from './MemoizedOutlet';
import { Text } from '@chakra-ui/react';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { logSetUserId, logUserInformation } from '../../common/utils/logger';

export const LayoutPage = () => {
	const [fetchUserDetails, { data: user, isLoading, isError }] =
		useLazyGetUserDetailsQuery();
	const dispatch = useDispatch();
	const authInfo = useAuthInfo();
	const { accessToken, language } = useAppSelector(
		(state: RootState) => state.applicationState
	);

	useEffect(() => {
		dispatch(setAccessToken(authInfo?.accessToken ?? ''));
	}, [dispatch, authInfo]);

	useEffect(() => {
		if (user?.language) {
			dispatch(setLanguage(user.language));
		}
	}, [user]);

	useEffect(() => {
		if (user) {
			logUserInformation({
				'First Name': user.firstName ?? '',
				'Last Name': user.lastName ?? '',
				Email: user.email ?? '',
			});
			logSetUserId(user.id);
		}
	}, [user]);

	useEffect(() => {
		void fetchUserDetails();
	}, [fetchUserDetails]);

	if (isError) {
		return <Text>error</Text>;
	}
	if (isLoading || !user || !language) {
		return <Text>Loading</Text>;
	}

	return <>{accessToken && <MemoizedOutlet accessToken={accessToken} />}</>;
};
