import { Flex } from '@chakra-ui/react';
import { StoryDTO } from '@jam/api-types';
import { Clock, PhoneCall, Users } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { Chip } from './Chip';
import { XpIcon } from './Icons';

type StoryLabelsProps = { story: StoryDTO; hideXp?: boolean };

export const StoryLabels = ({ story, hideXp }: StoryLabelsProps) => {
	const { t } = useTranslation('story');

	if (!story) {
		return null;
	}

	return (
		<Flex flexWrap={'wrap'} gap="1">
			{story.situation.conversation_type === 'call' && (
				<Chip
					bg={'white'}
					minH={'28px'}
					icon={<PhoneCall size={20} />}
					text={t(story.situation.conversation_type)}
				/>
			)}

			{story.situation.conversation_type === 'live' && (
				<Chip
					minH={'28px'}
					bg={'white'}
					icon={<Users size={17} />}
					text={t('live')}
				/>
			)}

			{story.duration && (
				<Chip
					minH={'28px'}
					bg={'white'}
					icon={<Clock size={20} />}
					text={`${story.duration} min`}
				/>
			)}

			{story.difficulty && (
				<Chip minH={'28px'} bg="white" text={t(story.difficulty)} />
			)}
			{!hideXp && story.session_xp && story.story_xp && (
				<Chip
					bg="white"
					minH={'28px'}
					icon={<XpIcon />}
					text={`${story.session_xp} | ${story.story_xp}`}
				/>
			)}
		</Flex>
	);
};
