import { TeamPerformanceInsights, UserUsage } from '@jam/api-types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const api = createApi({
	reducerPath: 'dashoard-api',
	baseQuery: fetchBaseQuery({
		baseUrl: `${
			process.env
				.REACT_APP_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}/api/v1`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getUsageInsights: builder.query<{ userUsages: UserUsage[] }, void>({
			query: () => 'insights/usage',
		}),
		getTeamPerformanceInsights: builder.query<TeamPerformanceInsights, void>({
			query: () => 'insights/team-performance',
		}),
	}),
});

export const { useGetUsageInsightsQuery, useGetTeamPerformanceInsightsQuery } =
	api;
export default api;
