import { Badge, Flex, Tag, Text } from '@chakra-ui/react';

type DeltaBadgeProps = {
	number: number;
	delta: number;
};

export const DeltaBadge = ({ number, delta }: DeltaBadgeProps) => {
	return (
		<Flex gap={'2'}>
			<Text>{number}</Text>
			<Tag colorScheme={delta > 0 ? 'green' : 'red'}>
				{delta > 0 ? `+${delta}` : delta}
			</Tag>
		</Flex>
	);
};
