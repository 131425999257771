import { Flex } from '@chakra-ui/react';
import React from 'react';
import { TrainingPerformanceChart } from './TrainingPerformanceChart';
import TrainingPerformanceTable from './TrainingPerformanceTable';

export const TrainingPerformanceScreen = () => {
	return (
		<Flex gap={'5'} direction={'column'}>
			<TrainingPerformanceChart />
			<TrainingPerformanceTable />
		</Flex>
	);
};
