/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Box,
	Button,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import {
	ArrowUp,
	ArrowDown,
	ArrowsDownUp,
	CaretRight,
	CaretLeft,
	CaretUpDown,
} from '@phosphor-icons/react';
import {
	ColumnDef,
	ColumnFiltersState,
	flexRender,
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { CustomFilter } from './Filters/CustomFilter';
import { useTranslation } from 'react-i18next';

type CustomTableProps<T> = {
	data: T[];
	columns: ColumnDef<T, any>[];
	customFilters?: ColumnFiltersState;
	onRowClick?: (row: T) => void;
};

const CustomTable = <T,>({
	columns,
	data,
	customFilters,
	onRowClick = () => {},
}: CustomTableProps<T>) => {
	const { t } = useTranslation('common');
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(1);
	const numberOfPages = Math.ceil(data.length / rowsPerPage);
	const table = useReactTable({
		getPaginationRowModel: getPaginationRowModel(),
		columns,
		state: {
			columnFilters: customFilters,
			pagination: {
				pageIndex: page - 1,
				pageSize: rowsPerPage,
			},
		},
		data,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(), // needed for client-side filtering
		getFacetedRowModel: getFacetedRowModel(), // client-side faceting
		getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
		getFacetedMinMaxValues: getFacetedMinMaxValues(), // generate min/max values for range filter
	});
	return (
		<Box overflowX={'auto'}>
			<Table>
				<Thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<Th
										borderTop={'0px'}
										bg="white"
										key={header.id}
										colSpan={header.colSpan}
									>
										{header.column.columnDef.meta?.hideTitle ? null : (
											<>
												<Flex alignItems={'center'}>
													<Box
														display={'flex'}
														alignItems={'center'}
														textColor={'#757575'}
														fontWeight={'medium'}
														onClick={header.column.getToggleSortingHandler()}
													>
														{flexRender(
															header.column.columnDef.header,
															header.getContext()
														)}
														{{
															asc: <ArrowUp height={'15px'} width={'15px'} />,
															desc: (
																<ArrowDown height={'15px'} width={'15px'} />
															),
														}[header.column.getIsSorted() as string] ?? (
															<ArrowsDownUp height={'15px'} width={'15px'} />
														)}
													</Box>
													{header.column.columnDef.meta?.customFilter ? (
														<Box ml="4">
															{header.column.columnDef.meta.customFilter({
																column: header.column,
															})}
														</Box>
													) : null}
													{header.column.columnDef.meta?.filterVariant ? (
														<Box ml="4">
															<CustomFilter column={header.column} />
														</Box>
													) : null}
												</Flex>
											</>
										)}
									</Th>
								);
							})}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map((row) => (
						<Tr
							key={row.id}
							cursor={'pointer'}
							onClick={() => {
								onRowClick(row.original);
							}}
						>
							{row.getVisibleCells().map((cell) => (
								<Td key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</Td>
							))}
						</Tr>
					))}
				</Tbody>
			</Table>
			<Box my="4">
				<Flex gap="5" ml="auto" w="fit-content">
					<Flex gap={2} alignItems={'center'}>
						<Text fontWeight={'medium'} fontSize={'12px'}>
							{t('rows_per_page')}
						</Text>
						<Menu>
							<MenuButton
								bg="transparent"
								border={'1px'}
								borderColor={'black'}
								size={'xs'}
								color={'black'}
								as={Button}
								rightIcon={<CaretUpDown color="black" />}
							>
								{rowsPerPage}
							</MenuButton>
							<MenuList px="5px" w="50px" borderRadius={'24px'}>
								{[10, 20, 30, 40, 50].map((value) => (
									<MenuItem
										borderRadius={'12px'}
										key={value}
										onClick={() => {
											setRowsPerPage(value);
											setPage(1);
										}}
									>
										{value}
									</MenuItem>
								))}
							</MenuList>
						</Menu>
					</Flex>
					<Flex alignItems={'center'}>
						<Text fontWeight={'medium'} fontSize={'12px'}>
							{t('page', { page, pages: numberOfPages })}
						</Text>
						<IconButton
							bg="transparent"
							border={'1px'}
							borderColor={'black'}
							icon={<CaretLeft color="black" />}
							size="xs"
							ml="2"
							aria-label={''}
							onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
						/>
						<IconButton
							bg="transparent"
							border={'1px'}
							borderColor={'black'}
							icon={<CaretRight color="black" />}
							size="xs"
							ml="2"
							aria-label={''}
							onClick={() =>
								setPage((prev) => Math.min(prev + 1, numberOfPages))
							}
						/>
					</Flex>
				</Flex>
			</Box>
		</Box>
	);
};

export default CustomTable;
