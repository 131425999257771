import { Divider, Stack } from '@chakra-ui/react';
import { AccountDataForm } from './AccountDataForm';
import { PasswordChangeForm } from './PasswordChangeForm';
import { LanguageChangeForm } from './LanguageChangeForm';
import { useAuthInfo } from '@propelauth/react';
import { useGetUserDetailsQuery } from '../../../redux/api/userApi';

export const PersonalSettingsTab = () => {
	const { user } = useAuthInfo();
	const { data: userInfo } = useGetUserDetailsQuery();
	if (!user) return null;
	const { firstName, lastName, email, pictureUrl } = user;
	return (
		<Stack divider={<Divider />} direction={'column'} py="10" spacing={'10'}>
			<AccountDataForm
				pictureUrl={pictureUrl}
				firstName={firstName}
				lastName={lastName}
				email={email}
			/>
			<PasswordChangeForm />
			{userInfo && <LanguageChangeForm language={userInfo.language} />}
		</Stack>
	);
};
