import { Language } from '@jam/api-types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

type ApplicationState = {
	language: 'en' | 'de' | null;
	accessToken: string;
	currentCallStartTime: number | null;
	hasUserMadeCall: boolean;
};

const initialState: ApplicationState = {
	language: null,
	accessToken: '',
	currentCallStartTime: null,
	hasUserMadeCall: false,
};

export const applicationStateSlice = createSlice({
	name: 'applicationState',
	initialState,
	reducers: {
		setLanguage: (state: ApplicationState, action: PayloadAction<Language>) => {
			state.language = action.payload;
		},
		setHasUserMadeCall: (
			state: ApplicationState,
			action: PayloadAction<boolean>
		) => {
			state.hasUserMadeCall = action.payload;
		},
		setCurrentCallStartTime: (
			state: ApplicationState,
			action: PayloadAction<number | null>
		) => {
			state.currentCallStartTime = action.payload;
		},
		setAccessToken: (
			state: ApplicationState,
			action: PayloadAction<string>
		) => {
			state.accessToken = action.payload;
		},
	},
});

export const {
	setLanguage,
	setAccessToken,
	setCurrentCallStartTime,
	setHasUserMadeCall,
} = applicationStateSlice.actions;

export const selectLanguage = (state: RootState) =>
	state.applicationState.language ?? 'de';

export const selectHasUserMadeCall = (state: RootState) =>
	state.applicationState.hasUserMadeCall;

export const selectCurrentCallStartTime = (state: RootState) =>
	state.applicationState.currentCallStartTime;
