import { useQueryParam, withDefault, NumberParam } from 'use-query-params';

export const useActiveTabIndex = () => {
	const [activeTabIndex, setActiveTabIndex] = useQueryParam<number | null>(
		'activeTabIndex',
		withDefault(NumberParam, null)
	);

	return { activeTabIndex, setActiveTabIndex };
};
