import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type MicPermissionModalProps = {
	isOpen: boolean;
	onClose: () => void;
};

const MicPermissionModal = ({ isOpen, onClose }: MicPermissionModalProps) => {
	const { t } = useTranslation('call');

	const onPermissionRequest = () => {
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{t('mic_permissions_modal_title')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>{t('mic_permissions_modal_text')}</ModalBody>

				<ModalFooter>
					<Button
						id="mic-permissions-modal-button"
						borderRadius={'32px'}
						textColor={'white'}
						bg={'#404040'}
						gap={'16px'}
						_hover={{ bg: '#404040' }}
						onClick={onPermissionRequest}
						colorScheme="blue"
						mr={3}
					>
						{t('mic_permissions_button_text')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default MicPermissionModal;
