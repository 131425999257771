import { TeamPerformanceData } from '../../../data';
import { LineChart, XAxis, YAxis, Line } from 'recharts';
import { Box, Flex, Link, Text, Tooltip } from '@chakra-ui/react';
import { Info } from '@phosphor-icons/react';
import { useActiveScreen } from '../../../../hooks/useActiveScreen';

type TeamPerformanceChartProps = {
	showLearnMore?: boolean;
};

export const TeamPerformanceChart = ({
	showLearnMore,
}: TeamPerformanceChartProps) => {
	const { setActiveScreen } = useActiveScreen();
	return (
		<Box py="4" pr="10" maxWidth="450px">
			<Text mb="2" ml="16" fontSize={'2xl'} fontWeight={'semibold'}>
				Team performance
			</Text>
			<Flex gap="1">
				<Text
					mb="4"
					ml="16"
					fontSize={'sm'}
					color="gray.500"
					fontWeight={'medium'}
				>
					Development of average score across all users and items over time.
				</Text>
				<Tooltip
					label="Score = number of items addressed correctly divided by total number of items presented"
					fontSize="md"
				>
					<Info width="25px" />
				</Tooltip>
			</Flex>
			<LineChart width={450} height={250} data={TeamPerformanceData}>
				<XAxis dataKey="week" />
				<YAxis domain={[0, 100]} />
				<Line
					type="monotone"
					name="Stories per User"
					dataKey="firstSessionPerformance"
					stroke="#8884d8"
				/>
				<Line
					type="monotone"
					name="Sessions per User"
					dataKey="secondSessionPerformance"
					stroke="#82ca9d"
				/>
			</LineChart>
			{showLearnMore && (
				<Link
					_hover={{ textDecoration: 'underline' }}
					ml="10"
					color="blue.500"
					onClick={() => setActiveScreen(3)}
				>
					Learn More
				</Link>
			)}
		</Box>
	);
};
