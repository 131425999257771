import {
	Accordion,
	AccordionItem,
	Flex,
	AccordionButton,
	AccordionPanel,
	Divider,
	Box,
	Text,
	Stack,
	useDisclosure,
} from '@chakra-ui/react';
import { Info, Minus, Plus, ThumbsDown, ThumbsUp } from '@phosphor-icons/react';
import { FeedbackNotFinished } from './FeedbackNotFinished';
import { Trans, useTranslation } from 'react-i18next';
import { NegativeUserFeedbackModal } from './NegativeUserFeedbackModal';
import { Chip } from '../../../common/components/Chip';
import { XpIcon } from '../../../common/components/Icons';
import {
	CallSession,
	SolvedStatus,
	FeedbackStatus,
	LearnerStoryItemFeedback,
	Item,
	StoryDTO,
	AnalysisState,
} from '@jam/api-types';
import {
	useAddUserItemFeedbackMutation,
	useGetUserItemFeedbackBySessionQuery,
} from '../../../redux/api/learnerApi';
import { useState } from 'react';

type SessionFeedbackProps = {
	story: StoryDTO;
	session: CallSession | null | undefined;
	completedItems: number;
	allItems: number;
};

export const SolvedStatusToColorMap: Record<
	SolvedStatus,
	{
		bg: string;
		color: string;
	}
> = {
	[SolvedStatus.Solved]: { bg: '#EAFDEE', color: '#147129' },
	[SolvedStatus.PartlySolved]: { bg: 'yellow.100', color: 'yellow.500' },
	[SolvedStatus.NotSolved]: {
		bg: 'rgba(255, 0, 0, 0.1)',
		color: 'rgba(255, 0, 0, 0.6)',
	},
	[SolvedStatus.Undetected]: { bg: '#F5F5F5', color: '#757575' },
};

export const SessionFeedback = ({
	session,
	story,
	completedItems,
	allItems,
}: SessionFeedbackProps) => {
	const { t } = useTranslation('call');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [activeItem, setActiveItem] = useState<string>('');
	const [addUserItemFeedback] = useAddUserItemFeedbackMutation();
	const { data: userFeedbacksPerItem } = useGetUserItemFeedbackBySessionQuery(
		session?.id ?? '',
		{
			skip: !session,
		}
	);

	if (!session) {
		return null;
	}
	if (!session.analysis || session.analysis.state !== AnalysisState.DONE) {
		return <FeedbackNotFinished status={FeedbackStatus.notStarted} />;
	}

	const renderUserFeedback = (
		item: Item,
		userFeedback: LearnerStoryItemFeedback | null
	) => {
		const hasUserFeedback = !!userFeedback;
		if (!hasUserFeedback) {
			return (
				<Flex gap="2" mt="4" alignItems={'center'} w="fit-content" ml="auto">
					<ThumbsUp
						cursor={'pointer'}
						size={15}
						onClick={() => {
							void addUserItemFeedback({
								sessionId: session.id,
								storyItemReferenceId: item.id,
								positive: true,
							});
						}}
					/>
					<ThumbsDown
						cursor={'pointer'}
						size={15}
						onClick={() => {
							setActiveItem(item.id);
							onOpen();
						}}
					/>
				</Flex>
			);
		}

		if (userFeedback.positive) {
			return (
				<Flex gap="2" mt="4" alignItems={'center'} w="fit-content" ml="auto">
					<ThumbsUp weight="fill" color="#757575" size={15} />
				</Flex>
			);
		}
		if (!userFeedback.positive) {
			return (
				<Flex gap="2" mt="4" alignItems={'center'} w="fit-content" ml="auto">
					<ThumbsDown weight="fill" color="#757575" size={15} />
				</Flex>
			);
		}
	};

	const notSolvedItemsIndexes = session.analysis.items
		.map((item, index) => (item.solved !== SolvedStatus.Solved ? index : -1))
		.filter((x) => x >= 0);

	let text = 'solved_some_tasks';
	if (completedItems === allItems) {
		text = 'solved_all_tasks';
	} else if (completedItems === 0) {
		text = 'solved_no_tasks';
	}

	const conversationItemIds = story.items_conversation_feedback.map(
		(item) => item.id
	);

	const sortedItems = session.analysis.items.slice().sort((a, b) => {
		const aIndex = conversationItemIds.indexOf(
			a.conversationItemReferenceId ?? ''
		);
		const bIndex = conversationItemIds.indexOf(
			b.conversationItemReferenceId ?? ''
		);
		return aIndex - bIndex;
	});

	return (
		<Flex direction={'column'} gap="4">
			{allItems > 0 && (
				<Flex
					border={'1px'}
					borderColor={'#D9D9D9'}
					px="8"
					py="6"
					gap="2"
					borderRadius={'24px'}
					alignItems={'center'}
				>
					<Box
						border={'1px'}
						p="2"
						borderRadius={'8px'}
						borderColor={'#D9D9D9'}
					>
						<Info size={20} />
					</Box>
					<Box fontWeight={'500'}>
						<Trans
							i18nKey={`call:${text}`}
							values={{
								completedItems,
								allItems,
								xp:
									completedItems === allItems
										? story.story_xp ?? 100
										: story.session_xp ?? 20,
							}}
						>
							Good effort! You solved
							<Text display={'inline'} color="#4241E4">
								{`${completedItems}/${allItems}`} items
							</Text>
							and earned 20 <XpIcon /> . Review your feedback and try again.
						</Trans>
					</Box>
				</Flex>
			)}
			<Accordion allowMultiple defaultIndex={notSolvedItemsIndexes}>
				<NegativeUserFeedbackModal
					sessionId={session.id}
					itemId={activeItem}
					isOpen={isOpen}
					onClose={() => {
						onClose();
						setActiveItem('');
					}}
				/>
				{sortedItems.map((item) => {
					const userFeedback =
						userFeedbacksPerItem?.find(
							(x) => x.storyItemReferenceId === item.id
						) ?? null;

					return (
						<AccordionItem mb="5" key={item.id} border={0}>
							{({ isExpanded }) => (
								<Box
									borderRadius={'24px'}
									border={'1px'}
									borderColor={'#D9D9D9'}
									px="4"
									py="6"
								>
									<AccordionButton
										_hover={{ background: 'transparent' }}
										w="100%"
									>
										<Flex w="100%" justifyContent={'space-between'}>
											<Flex direction={'column'} gap="2">
												<Chip
													bg={
														SolvedStatusToColorMap[
															item.solved ?? SolvedStatus.Undetected
														].bg
													}
													color={
														SolvedStatusToColorMap[
															item.solved ?? SolvedStatus.Undetected
														].color
													}
													w="fit-content"
													border={0}
													py="1"
													px={2}
													fontSize={'12px'}
													fontWeight={'500'}
													text={t(item.solved ?? SolvedStatus.Undetected)}
												/>
												<Text
													fontWeight={'500'}
													w="fit-content"
													fontSize={'16px'}
												>
													{item.name}
												</Text>
											</Flex>

											{isExpanded ? <Minus size={20} /> : <Plus size={20} />}
										</Flex>
									</AccordionButton>
									<AccordionPanel>
										<Divider color={'#757575'} mt="2" mb="4" />
										<Stack direction={'row'} spacing={4}>
											<Flex w="50%" direction={'column'} gap="2" mt="3">
												<Text fontSize="16px">{t('task_feedback')}</Text>
												<Flex alignItems={'center'} gap="2">
													<Text
														fontWeight={'300'}
														color="#757575"
														fontSize="14px"
													>
														{item.explanation}
													</Text>
												</Flex>
											</Flex>
											<Flex w="50%" direction={'column'} gap="2" mt="3">
												<Text fontSize="16px">{t('nextTime')}</Text>

												<Flex alignItems={'center'} gap="2">
													<Text
														fontWeight={'300'}
														color="#757575"
														fontSize="14px"
													>
														{item.suggestion}
													</Text>
												</Flex>
											</Flex>
										</Stack>
										{renderUserFeedback(item, userFeedback)}
									</AccordionPanel>
								</Box>
							)}
						</AccordionItem>
					);
				})}
			</Accordion>
		</Flex>
	);
};
