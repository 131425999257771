import { Stack, useDisclosure, Radio, RadioGroup } from '@chakra-ui/react';
import { z, ZodType } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import PopoverFilter from './PopoverFilter';

type SingleSelectForm = {
	value: string;
};

export const SingleSelectSchema: ZodType<SingleSelectForm> = z.object({
	value: z.string(),
});

type SingleSelectFilterProps = {
	options: string[];
	hasValue?: boolean;
	onApply: (value: string | undefined) => void;
};

const SingleSelectFilter = ({
	options,
	onApply,
	hasValue,
}: SingleSelectFilterProps) => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	const { control, getValues } = useForm<SingleSelectForm>({
		resolver: zodResolver(SingleSelectSchema),
	});
	return (
		<PopoverFilter
			hasValue={hasValue ?? false}
			onClear={() => onApply(undefined)}
			isOpen={isOpen}
			onToggle={onToggle}
			onClose={onClose}
			onApply={() => {
				const { value } = getValues();
				onApply(value);
			}}
		>
			<Controller
				control={control}
				name="value"
				render={({ field }) => (
					<RadioGroup {...field}>
						<Stack spacing="20px">
							{options.map((option) => (
								<Radio key={option} value={option}>
									{option}
								</Radio>
							))}
						</Stack>
					</RadioGroup>
				)}
			/>
		</PopoverFilter>
	);
};

export default SingleSelectFilter;
