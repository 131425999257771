import { Box, Flex, Progress, SimpleGrid, Text } from '@chakra-ui/react';
import { Package } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { Chip } from '../../../common/components/Chip';
import { TrackLabels } from '../../../common/components/TrackLabels';
import { calculateTrackCompletion } from '../../../common/utils/calculateCompletion';
import { useGetFirstNotCompletedStory } from '../../../common/utils/getfirstNotCompletedStory';
import { useGetJourneyDetailsQuery } from '../../../redux/api/cmsApi';
import { useGetUserJourneyContentAllocationQuery } from '../../../redux/api/learnerApi';

export const AllTracks = () => {
	const { t } = useTranslation('gym');
	const { data: userJourney } = useGetUserJourneyContentAllocationQuery();
	const activeStory = useGetFirstNotCompletedStory();
	const { data: journey } = useGetJourneyDetailsQuery(
		userJourney?.journeyReferenceId || '',
		{
			skip: !userJourney?.journeyReferenceId,
		}
	);

	if (!journey || !userJourney) {
		return null;
	}
	const activeTrack: string = activeStory?.track.id || '';
	const { tracks } = journey;

	const otherTracks = tracks.filter((track) => track.id !== activeTrack);

	if (!otherTracks.length) {
		return null;
	}

	return (
		<Box my="10">
			<Flex mb="6" alignItems={'center'} gap="2">
				<Text
					w="fit-content"
					fontSize="2xl"
					color={'#757575'}
					fontWeight="semibold"
				>
					{t('all_tracks')}
				</Text>
			</Flex>
			<Flex direction="column" gap="4">
				{otherTracks.map((track) => {
					const completion: {
						completed: number;
						total: number;
						percentage: number;
					} = calculateTrackCompletion(track, userJourney);
					return (
						<Box
							key={track.id}
							p="4"
							border={'1px'}
							borderColor={'#d9d9d9'}
							borderRadius={'32px'}
						>
							<Flex justify={'space-between'}>
								<Text fontSize="24px" fontWeight="medium">
									{track.title}
									<TrackLabels track={track} />
								</Text>
								{/* <Box>
									<Button
										borderRadius={'32px'}
										textColor={'white'}
										bg={'#4241E4'}
										gap={'16px'}
										_hover={{ bg: '#4241E4' }}
										onClick={() => {}}
									>
										<Text>{t('resume')}</Text>
										<Image src="/icons/right-arrow.svg" />
									</Button>
								</Box> */}
							</Flex>
							<Flex mt="4" gap={'2'} alignItems={'center'}>
								<Chip
									display={'inline-block'}
									bg={'#D5C9FF'}
									text={`${completion.completed}/${completion.total}`}
								/>
								<Box w="100%">
									<Progress
										borderRadius={'25px'}
										size={'lg'}
										value={completion.completed}
										max={completion.total}
									/>
								</Box>
							</Flex>
							<SimpleGrid mt="4" columns={4}>
								<Flex gap="2">
									<Flex
										p="2"
										border="1px"
										borderRadius={'12px'}
										borderColor={'#d9d9d9'}
										justifyContent={'center'}
										alignItems={'center'}
									>
										<Package size={32} />
									</Flex>
									<Box>
										<Text fontSize={'12px'} color="#757575">
											Content
										</Text>
										<Text fontSize={'14px'} fontWeight={'medium'}>
											{track.stories.length} Stories
										</Text>
									</Box>
								</Flex>
							</SimpleGrid>
						</Box>
					);
				})}
			</Flex>
		</Box>
	);
};
