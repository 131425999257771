import { useQueryParam, withDefault, StringParam } from 'use-query-params';

export const useActiveContentAllocationId = () => {
	const [activeContentAllocationId, setActiveContentAllocationId] =
		useQueryParam<string | null>(
			'contentAllocationId',
			withDefault(StringParam, null)
		);

	return { activeContentAllocationId, setActiveContentAllocationId };
};
