import { configureStore } from '@reduxjs/toolkit';
import { applicationStateSlice } from './slice';
import userApi from './api/userApi';
import learnerApi from './api/learnerApi';
import dashboardApi from './api/dashboardApi';
import cmsApi from './api/cmsApi';
import accountApi from './api/accountApi';

const store = configureStore({
	reducer: {
		applicationState: applicationStateSlice.reducer,
		[userApi.reducerPath]: userApi.reducer,
		[learnerApi.reducerPath]: learnerApi.reducer,
		[dashboardApi.reducerPath]: dashboardApi.reducer,
		[cmsApi.reducerPath]: cmsApi.reducer,
		[accountApi.reducerPath]: accountApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(userApi.middleware)
			.concat(learnerApi.middleware)
			.concat(dashboardApi.middleware)
			.concat(cmsApi.middleware)
			.concat(accountApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
