import { StoryDTO } from '@jam/api-types';
import { useGetUserStoriesQuery } from '../../redux/api/cmsApi';

export const useGetStoryDetails = (
	storyId: string | undefined
): StoryDTO | undefined => {
	const { data } = useGetUserStoriesQuery(undefined, {
		skip: !storyId,
	});
	return data?.find((story) => story.id === storyId);
};
