import { useTranslation } from 'react-i18next';
import { CircularProgress, Flex, Image, Text } from '@chakra-ui/react';
import { FeedbackStatus } from '@jam/api-types';

type FeedbackNotFinishedProps = {
	status: Exclude<FeedbackStatus, 'completed'>;
};

const textToStatusMap: Record<Exclude<FeedbackStatus, 'completed'>, string> = {
	not_started: 'feedback_not_started_text',
	running: 'feedback_running_text',
	error: 'feedback_error_text',
};

export const FeedbackNotFinished = ({ status }: FeedbackNotFinishedProps) => {
	const { t } = useTranslation('call');

	return (
		<Flex
			h="100%"
			direction="column"
			gap="10"
			justifyContent={'center'}
			alignItems={'center'}
		>
			{status !== FeedbackStatus.error ? (
				<CircularProgress
					size="200px"
					isIndeterminate
					color="green.400"
					thickness={'3px'}
				/>
			) : (
				<Image src="/dark-logo.svg" w="60px" />
			)}
			<Text color="#000000CC" size="lg">
				{t(textToStatusMap[status])}
			</Text>
		</Flex>
	);
};
