import {
	Box,
	Accordion,
	AccordionItem,
	Flex,
	AccordionButton,
	AccordionPanel,
	Divider,
	Text,
} from '@chakra-ui/react';
import { StoryDTO } from '@jam/api-types';
import {
	Circle,
	Dot,
	Minus,
	Plus,
	Target,
	WarningCircle,
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
type HintsAndGoalsProps = {
	story: StoryDTO;
};

export const HintsAndGoals = ({ story }: HintsAndGoalsProps) => {
	const { t } = useTranslation('call');
	const goal = story.situation.goal;
	if (!goal) {
		return null;
	}
	const scenario = story.situation.description;
	return (
		<Flex direction={'column'} gap="4">
			<Flex
				border={'1px'}
				borderColor={'#D9D9D9'}
				px="8"
				py="6"
				gap="2"
				borderRadius={'24px'}
				alignItems={'center'}
			>
				<Box border={'1px'} p="2" borderRadius={'8px'} borderColor={'#D9D9D9'}>
					<Target size={20} />
				</Box>
				<Text fontSize={'16px'}>{goal}</Text>
			</Flex>
			<Accordion allowMultiple defaultIndex={[0]}>
				<AccordionItem border={0}>
					{({ isExpanded }) => (
						<Box
							borderRadius={'24px'}
							border={'1px'}
							borderColor={'#D9D9D9'}
							px="4"
							py="6"
						>
							<AccordionButton _hover={{ background: 'transparent' }} w="100%">
								<Flex
									alignItems={'center'}
									w="100%"
									justifyContent={'space-between'}
								>
									<Flex gap="2" alignItems={'center'}>
										<Box
											border={'1px'}
											p="2"
											borderRadius={'8px'}
											borderColor={'#D9D9D9'}
										>
											<Circle size={20} />
										</Box>
										<Text fontSize={'16px'}>{t('scenario')}</Text>
									</Flex>

									{isExpanded ? <Minus size={20} /> : <Plus size={20} />}
								</Flex>
							</AccordionButton>
							<AccordionPanel>
								<Divider color={'#757575'} mt="2" mb="4" />
								<Flex direction={'column'} gap="2" mt="3">
									{scenario
										.trim()
										.split('.')
										.filter((x) => !!x)
										.map((sentence) => (
											<Flex alignItems={'center'} gap="2" key={sentence}>
												<Box flexShrink={0}>
													<Dot color="#757575" size={17} />
												</Box>
												<Text
													fontWeight={'300'}
													color="#757575"
													fontSize="14px"
												>
													{sentence}
												</Text>
											</Flex>
										))}
								</Flex>
							</AccordionPanel>
						</Box>
					)}
				</AccordionItem>
				<AccordionItem mt="10" border={0}>
					{({ isExpanded }) => (
						<Box
							borderRadius={'24px'}
							border={'1px'}
							borderColor={'#D9D9D9'}
							px="4"
							py="6"
						>
							<AccordionButton _hover={{ background: 'transparent' }} w="100%">
								<Flex
									alignItems={'center'}
									w="100%"
									justifyContent={'space-between'}
								>
									<Flex gap="2" alignItems={'center'}>
										<Box
											border={'1px'}
											p="2"
											borderRadius={'8px'}
											borderColor={'#D9D9D9'}
										>
											<WarningCircle size={20} />
										</Box>
										<Text fontSize={'16px'}>{t('hints')}</Text>
									</Flex>
									{isExpanded ? <Minus size={20} /> : <Plus size={20} />}
								</Flex>
							</AccordionButton>
							<AccordionPanel>
								<Divider color={'#757575'} mt="2" mb="4" />
								<Flex direction={'column'} gap="2" mt="3">
									{story.situation.hints.map((h) => (
										<Flex alignItems={'center'} gap="2" key={h.hint}>
											<Box flexShrink={0}>
												<Dot color="#757575" size={17} />
											</Box>
											<Text fontWeight={'300'} color="#757575" fontSize="14px">
												{h.hint}
											</Text>
										</Flex>
									))}
								</Flex>
							</AccordionPanel>
						</Box>
					)}
				</AccordionItem>
			</Accordion>
		</Flex>
	);
};
