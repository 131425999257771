import { Flex, Avatar, Box, Image } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { Chip } from '../../../common/components/Chip';
import { useNavigate } from 'react-router-dom';
import { List } from '@phosphor-icons/react';
import { useGetUserTotalExperienceQuery } from '../../../redux/api/learnerApi';

type SalutationProps = {
	items: {
		text: string;
		to?: string;
	}[];
};

export const Breadcrumbs = ({ items }: SalutationProps) => {
	const { user: authUser } = useAuthInfo();
	const { data: exp } = useGetUserTotalExperienceQuery();
	const navigate = useNavigate();
	const name = authUser?.firstName || '';
	const profileImage = authUser?.pictureUrl || '';
	return (
		<Box w="100%" mb="5" borderRadius={'md'}>
			<Flex
				direction={{ base: 'column', lg: 'row' }}
				gap={10}
				justifyContent={'space-between'}
				alignItems={{ base: 'column', lg: 'center' }}
				w="100%"
			>
				<Flex gap={'8px'}>
					{items.map((item) => (
						<Chip
							cursor={'pointer'}
							onClick={() => {
								if (item.to) navigate(item.to);
							}}
							key={item.text}
							text={item.text}
						/>
					))}
				</Flex>
				<Flex gap={2}>
					{typeof exp === 'number' && exp >= 0 && (
						<Chip
							icon={<Image src="/icons/black-xp.svg" />}
							h="32px"
							px="3"
							bg="#EBF934"
							color={'black'}
							text={`${exp}`}
						/>
					)}
					<Flex
						bg="white"
						borderRadius={'full'}
						pr="1"
						cursor={'pointer'}
						borderWidth={'1px'}
						borderColor={'gray.200'}
						alignItems="center"
						gap="6px"
						onClick={() => {
							navigate('/settings');
						}}
					>
						<Avatar size="sm" src={profileImage} name={name} bg="grey" />
						<List width={'16px'} height={'16px'} />
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
};
