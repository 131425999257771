import {
	Box,
	Button,
	Flex,
	Image,
	keyframes,
	Progress,
	Stack,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';

import { StoryDTO } from '@jam/api-types';
import { Play, SpeakerSimpleHigh } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import Vapi from '@vapi-ai/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useBlocker } from 'react-router-dom';
import { useStoryStatus } from '../../../common/hooks/useStoryStatus';
import {
	useCreateCallMutation,
	useDeleteSessionMutation,
	useGetNextMissionQuery,
	useLazyGetStoryLastSessionQuery,
	useLazyGetStorySessionsQuery,
} from '../../../redux/api/learnerApi';
import {
	setCurrentCallStartTime,
	setHasUserMadeCall,
} from '../../../redux/slice';
import soundPhoneCallEnd from '../../call/assets/phone_call_end.mp3';
import soundPhoneCallPickUp from '../../call/assets/phone_call_pick_up.mp3';
import soundPhoneCall from '../../call/assets/phone_call_start.mp3';
import { useReferer } from '../../home/hooks/useReferer';
import { useActiveCallTabIndex } from '../hooks/useActiveCallTabIndex';
import { useActiveSession } from '../hooks/useActiveSession';
import { useShouldAskForFeedback } from '../hooks/useShouldAskForFeedback';
import CallTooShortDialog from './CallTooShortDialog';
import MicPermissionModal from './MicPermissionModal';
import UserFeedbackModal from './UserFeedbackModal';

enum CallStatus {
	notStarted = 'notStarted',
	startAgain = 'startAgain',
	loading = 'loading',
	started = 'started',
	finished = 'finished',
}

type CallerStatusConfig = {
	buttonText: string;
	fontColor: string;
	bgColor: string;
	buttonBorder?: string;
};

const statusConfigMap: Record<CallStatus, CallerStatusConfig> = {
	notStarted: {
		buttonText: 'start-call',
		bgColor: '#4241E4',
		fontColor: 'white',
	},
	startAgain: {
		buttonText: 'start-again',
		bgColor: '#4241E4',
		fontColor: 'white',
	},
	loading: {
		buttonText: 'loading-call',
		bgColor: '#4241E4',
		fontColor: 'white',
	},
	started: {
		buttonText: 'end-call',
		bgColor: 'white',
		fontColor: 'black',
		buttonBorder: 'linear-gradient(180deg,  #7D4CD0, #F98CC4)',
	},
	finished: {
		buttonText: 'start-again',
		bgColor: '#4241E4',
		fontColor: 'white',
	},
};
const vapiToken = process.env.REACT_APP_VAPI_PUBLIC_KEY;
const vapi = new Vapi(vapiToken as string);

const SERVER_URL = process.env.REACT_APP_ASSISTANT_SERVER_URL;

type CallerProps = {
	numberOfRounds?: number;
	isReturningCall?: boolean;
	story: StoryDTO;
	contentAllocationId: string | null;
	conversationType?: 'live' | 'call';
	completedItems: number;
	allItems: number;
};

export const Caller = ({
	numberOfRounds,
	story,
	completedItems,
	allItems,
	conversationType,
	contentAllocationId,
	isReturningCall,
}: CallerProps) => {
	const { t } = useTranslation('call');
	const { user } = useAuthInfo();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { setActiveSession } = useActiveSession();
	const dispatch = useDispatch();
	const { data: nextStory, isLoading } = useGetNextMissionQuery();
	const { ref } = useReferer();
	const storyId = story.id;
	const {
		isOpen: isOpenTriggerFeedback,
		onOpen: onOpenTriggerFeedback,
		onClose: onCloseTriggerFeedback,
	} = useDisclosure();

	const {
		isOpen: micModalIsOpen,
		onOpen: micModalOnOpen,
		onClose: micModalOnClose,
	} = useDisclosure();
	const toast = useToast();
	const storyStatus = useStoryStatus(storyId);
	const [botIsSpeaking, setBotIsSpeaking] = useState(false);

	const askForFeedback = useShouldAskForFeedback();
	const [seconds, setSeconds] = useState(0);
	const [micPermissionsGranted, setMicPermissionsGranted] = useState(false);
	const [createCall] = useCreateCallMutation();
	const [deleteSession] = useDeleteSessionMutation();
	const [getLastStoryQuery] = useLazyGetStoryLastSessionQuery();
	const [getStorySessionsQuery] = useLazyGetStorySessionsQuery();
	const { setActiveCallTabIndex } = useActiveCallTabIndex();

	const [sessionId, setSessionId] = useState<string | null>(null);
	const secondsRef = useRef(seconds);
	useEffect(() => {
		secondsRef.current = seconds;
	}, [seconds]);

	const soundStartCall = new Audio(soundPhoneCall);
	const soundEndCall = new Audio(soundPhoneCallEnd);
	const soundPickUp = new Audio(soundPhoneCallPickUp);
	const [callStatus, setCallStatus] = useState<CallStatus>(
		isReturningCall ? CallStatus.startAgain : CallStatus.notStarted
	);

	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (callStatus === CallStatus.started) {
			intervalRef.current = setInterval(() => {
				setSeconds((s) => s + 1);
			}, 1000);
		} else if (callStatus === CallStatus.finished) {
			if (intervalRef.current) clearInterval(intervalRef.current);
		}
		return () => {
			if (intervalRef.current) clearInterval(intervalRef.current);
		};
	}, [callStatus]);

	useEffect(() => {
		if (askForFeedback) {
			onOpen();
		}
	}, [askForFeedback]);

	useEffect(() => {
		return () => {
			dispatch(setHasUserMadeCall(false));
		};
	}, []);

	const cancelFeedbackGeneration = useCallback(async (): Promise<void> => {
		onCloseTriggerFeedback();
		await deleteSession(sessionId as string);
	}, [sessionId]);

	const finishCallProcedure = useCallback(async (): Promise<void> => {
		onCloseTriggerFeedback();
		// remove session from the URL we need to load last one
		setActiveSession(null);
		await getLastStoryQuery(storyId);
		await getStorySessionsQuery(storyId);
		dispatch(setHasUserMadeCall(true));
		setActiveCallTabIndex(1);
	}, []);

	const blocker = useBlocker(
		({ currentLocation, nextLocation }) =>
			callStatus === CallStatus.started &&
			currentLocation.pathname !== nextLocation.pathname
	);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (callStatus === CallStatus.started) {
				event.preventDefault();
				event.returnValue = ''; // Some browsers require setting this property.
			}
		};

		if (blocker.state === 'blocked') {
			toast({
				title: t('before_unload_title'),
				description: t('before_unload_text'),
				status: 'warning',
				duration: 5000,
				isClosable: true,
			});
		}

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [callStatus, blocker]);

	const finishCall = () => {
		const secondsValue = secondsRef.current;
		if (secondsValue == 0) {
			return;
		}
		setSeconds(0);
		dispatch(setCurrentCallStartTime(null));
		setCallStatus(CallStatus.finished);
		if (conversationType === 'call') {
			void soundEndCall.play();
		}
		if (!vapi) {
			return;
		}
		if (secondsValue >= 20) {
			void finishCallProcedure();
		} else {
			onOpenTriggerFeedback();
		}
	};

	const pulse = keyframes(
		botIsSpeaking
			? {
					'0%': { boxShadow: '0 0 0 2px rgba(204,169,44, 0.4)' },
					'20%': { boxShadow: '0 0 0 6px rgba(204,169,44, 0.2)' },
					'100%': { boxShadow: '0 0 0 2px rgba(204,169,44, 0.4)' },
				}
			: {}
	);

	// hook into Vapi events
	useEffect(() => {
		vapi.on('call-start', () => {
			dispatch(setCurrentCallStartTime(new Date().getTime()));

			setCallStatus(CallStatus.started);
			if (conversationType == 'call') {
				void soundPickUp.play();
			}
		});
		vapi.on('call-end', () => {
			finishCall();
		});
		vapi.on('speech-start', () => {
			setBotIsSpeaking(true);
		});

		vapi.on('speech-end', () => {
			setBotIsSpeaking(false);
		});

		vapi.on('error', () => {
			finishCall();
		});

		// we only want this to fire on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onCallStart = useCallback(async () => {
		try {
			if (conversationType == 'call') {
				void soundStartCall.play();
			}
			setCallStatus(CallStatus.loading);
			const payload = await createCall({
				storyId,
				contentAllocationId,
			}).unwrap();
			const assistantReferenceId: string = payload.call.assistantReferenceId;

			await vapi.start(assistantReferenceId, {
				metadata: {
					sessionId: payload.id,
					storyId,
					userId: user?.userId,
				},
				serverUrl: SERVER_URL,
			});

			setSessionId(payload.id);
		} catch (error) {
			setCallStatus(CallStatus.finished);
		}
	}, [contentAllocationId, storyId, conversationType]);

	useEffect(() => {
		navigator.mediaDevices
			.getUserMedia({
				audio: true,
			})
			.then(() => {
				setMicPermissionsGranted(true);
			})
			.catch(() => {
				setMicPermissionsGranted(false);
				toast({
					title: t('mic_permissions_error_title'),
					description: t('mic_permissions_error_text'),
					status: 'error',
					duration: 9000,
					isClosable: true,
				});
			});
	}, []);

	const onCallerClick = useCallback(() => {
		if (!micPermissionsGranted) {
			micModalOnOpen();
			return;
		}
		if (
			callStatus === CallStatus.notStarted ||
			callStatus === CallStatus.startAgain
		) {
			void onCallStart();
		} else if (callStatus === CallStatus.started) {
			setBotIsSpeaking(false);
			vapi.stop();
		} else if (callStatus === CallStatus.finished) {
			// user can start a new call
			void onCallStart();
		}
	}, [callStatus, finishCall, micPermissionsGranted, onCallStart]);

	const uiState = statusConfigMap[callStatus];

	const showNextStory =
		nextStory &&
		storyStatus === 'COMPLETED' &&
		callStatus !== CallStatus.started;

	const onNextStoryClick = useCallback(() => {
		if (nextStory) {
			window.location.href = `/call?storyId=${nextStory.storyReferenceId}&contentAllocationId=${nextStory.contentAllocationId}&ref=${ref ?? ''}`;
		}
	}, [nextStory, ref]);

	let primaryCta: 'next' | 'current' = 'current';
	if (storyStatus === 'COMPLETED' && showNextStory) {
		primaryCta = 'next';
	}

	let fontColor = uiState.fontColor;
	let bgColor = uiState.bgColor;

	if (primaryCta === 'next') {
		fontColor = '#4241E4';
		bgColor = 'white';
	}

	return (
		<Box>
			<MicPermissionModal isOpen={micModalIsOpen} onClose={micModalOnClose} />
			<CallTooShortDialog
				isOpen={isOpenTriggerFeedback}
				onCancelFeedback={() => {
					void cancelFeedbackGeneration();
				}}
				onGenerateFeedback={() => void finishCallProcedure()}
			/>
			<UserFeedbackModal
				sessionId={sessionId}
				storyId={storyId}
				isOpen={isOpen}
				onClose={onClose}
			/>
			<Flex
				bg="#FCFCFC"
				border={'1px'}
				borderRadius="24px"
				borderColor={'#D9D9D9'}
			>
				<Image
					w="150px"
					h="150px"
					borderRadius={'24px'}
					src={story?.persona?.base?.avatar_file?.url}
				/>
				<Flex mt="6" w="100%" gap="4" px="4" justifyContent={'space-between'}>
					<Flex direction={'column'} maxW="700px">
						<Text fontSize={'20px'} fontWeight={'medium'}>
							{story.title}
						</Text>
						<Stack
							gap={1}
							alignItems={'center'}
							direction={'row'}
							divider={<Text>·</Text>}
						>
							<Text color="#757575" fontSize={'12px'}>
								{story.persona.base.name}
							</Text>
							<Text color="#757575" fontSize={'12px'}>
								{story.persona.base.role}{' '}
								{story.persona.base.workplace.name &&
									`@${story.persona.base.workplace.name}`}
							</Text>
							{story.situation.conversation_type && (
								<Text color="#757575" fontSize={'12px'}>
									{t(story.situation.conversation_type)}
								</Text>
							)}
							{story.duration && (
								<Text color="#757575" fontSize={'12px'}>
									{story.duration} min
								</Text>
							)}
							{story.difficulty && (
								<Text color="#757575" fontSize={'12px'}>
									{t(story.difficulty)}
								</Text>
							)}
						</Stack>
						{allItems > 0 && (
							<Flex mt="7" gap={'2'} alignItems={'center'}>
								<Text fontSize={'12px'} color="#4241E4" fontWeight={'semibold'}>
									{`${completedItems}/${allItems}`} items
								</Text>
								<Progress
									border={'1px'}
									size={'md'}
									borderColor={'#D9D9D9'}
									borderRadius={'25px'}
									w={'350px'}
									value={completedItems}
									max={allItems}
								/>

								{typeof numberOfRounds === 'number' && (
									<Text fontSize={'12px'} color="#757575">
										{t('rounds_played', { count: numberOfRounds })}
									</Text>
								)}
							</Flex>
						)}
					</Flex>
					{!isLoading && (
						<Flex gap="2" alignItems={'center'} h="fit-content">
							{botIsSpeaking && <SpeakerSimpleHigh size={20} />}
							<Box
								borderRadius={'32px'}
								p="1px"
								bg={uiState.buttonBorder}
								animation={`${pulse} 0.4s infinite`}
							>
								{showNextStory && !botIsSpeaking && (
									<Button
										id="next-story-button"
										mr="2"
										borderRadius={'16px'}
										textColor={primaryCta === 'next' ? 'white' : '#4241E4'}
										borderColor={primaryCta === 'next' ? '#4241E4' : 'white'}
										bg={primaryCta === 'next' ? '#4241E4' : 'white'}
										gap={'8px'}
										border={'1px'}
										_hover={{ bg: primaryCta === 'next' ? '#4241E4' : 'white' }}
										onClick={() => void onNextStoryClick()}
									>
										<Text>{t('next_mission')}</Text>
										<Play weight="bold" size={14} />
									</Button>
								)}

								<Button
									id="call-button"
									borderRadius={'16px'}
									textColor={fontColor}
									bg={bgColor}
									gap={'8px'}
									border={'1px'}
									_hover={{ bg: bgColor }}
									onClick={() => void onCallerClick()}
								>
									<Text>{t(uiState.buttonText)}</Text>
									<Play weight="bold" size={14} />
								</Button>
							</Box>
						</Flex>
					)}
				</Flex>
			</Flex>
		</Box>
	);
};
