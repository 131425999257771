import { Box, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import React from 'react';
import { trainingPerformanceDetailsData } from '../../../data';

const TrainingPerformanceTable = () => {
	return (
		<Box overflowX="auto">
			<Table variant="simple">
				<Thead>
					<Tr>
						<Th>Name</Th>
						<Th>Description</Th>
						<Th>First Session Success Rate</Th>
						<Th>Second Session Success Rate</Th>
						<Th>Completion Rate</Th>
						<Th>Repetition Rate</Th>
						<Th>Rating</Th>
						<Th>Desired Quotes</Th>
						<Th>Undesired Quotes</Th>
						<Th>Most Successful Answers</Th>
						<Th>Key Challenges</Th>
					</Tr>
				</Thead>
				<Tbody>
					{trainingPerformanceDetailsData.map((session, index) => (
						<Tr key={index}>
							<Td>{session.name}</Td>
							<Td>{session.description}</Td>
							<Td>{session.firstSessionSuccessRate}</Td>
							<Td>{session.secondSessionSuccessRate}</Td>
							<Td>{session.completionRate}</Td>
							<Td>{session.repetitionRate}</Td>
							<Td>{session.rating}</Td>
							<Td>{session.desiredQuotes}</Td>
							<Td>{session.unDesiredQuotes}</Td>
							<Td>{session.mostSuccessfulAnswers}</Td>
							<Td>{session.keyChallenges}</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</Box>
	);
};

export default TrainingPerformanceTable;
