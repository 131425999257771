import { trainingPerformanceData } from '../../../data';
import { BarChart, CartesianGrid, XAxis, YAxis, Bar } from 'recharts';
import { Box, Flex, Link, Text, Tooltip } from '@chakra-ui/react';
import { Info } from '@phosphor-icons/react';
import { useActiveScreen } from '../../../../hooks/useActiveScreen';

type TrainingPerformanceChartProps = {
	showLearnMore?: boolean;
};

export const TrainingPerformanceChart = ({
	showLearnMore,
}: TrainingPerformanceChartProps) => {
	const { setActiveScreen } = useActiveScreen();
	return (
		<Box py="4" pr="10" maxWidth="450px">
			<Box>
				<Text mb="2" ml="16" fontSize={'2xl'} fontWeight={'semibold'}>
					Training performance
				</Text>
				<Flex gap="1">
					<Text
						mb="4"
						ml="16"
						fontSize={'sm'}
						color="gray.500"
						fontWeight={'medium'}
					>
						core in first session compared to maximum score in all sessions
						(average across all users and items)
					</Text>
					<Tooltip
						label="Score = number of items addressed correctly divided by total number of items presented"
						fontSize="md"
					>
						<Info width="25px" />
					</Tooltip>
				</Flex>
			</Box>
			<BarChart
				width={450}
				height={228}
				barSize={20}
				data={trainingPerformanceData}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" />
				<YAxis domain={[0, 100]} />
				<Bar dataKey="performance" fill="#8884d8" />
			</BarChart>
			{showLearnMore && (
				<Link
					_hover={{ textDecoration: 'underline' }}
					ml="10"
					color="blue.500"
					onClick={() => setActiveScreen(2)}
				>
					Learn More
				</Link>
			)}
		</Box>
	);
};
