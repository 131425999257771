import { Spinner } from '@chakra-ui/react';
import { UserRole } from '@jam/api-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserDetailsQuery } from '../../redux/api/userApi';

type RoleCheckProps = {
	WrappedComponent: JSX.Element;
	requiredRoles: UserRole[];
};

export const RoleCheck = ({
	WrappedComponent,
	requiredRoles,
}: RoleCheckProps): JSX.Element | null => {
	const { data } = useGetUserDetailsQuery();
	const userRole = data?.role;
	const nav = useNavigate();

	useEffect(() => {
		if (userRole == null) {
			return;
		}
		if (!requiredRoles.includes(userRole)) {
			nav('/users/home');
		}
	}, [userRole]);

	if (!userRole || !requiredRoles.includes(userRole)) {
		return <Spinner size={'xl'} />;
	}
	return WrappedComponent;
};
