import { useAuthInfo } from '@propelauth/react';
import { UserRole } from '../types/AuthTypes';

/**
 * checks if the user is a manager or owner in any of the organizations
 * @returns the orgId of the organization where the user is a manager or owner
 */
export const useIsManagerOrOwnerAtOrg = (): string | undefined => {
	const authInfo = useAuthInfo();
	const orgs = authInfo.orgHelper?.getOrgs();
	if (!orgs) {
		return;
	}
	for (const org of orgs) {
		const isManagerOrOwner =
			authInfo.accessHelper?.isRole(org.orgId, UserRole.Owner) ||
			authInfo.accessHelper?.isRole(org.orgId, UserRole.Manager);

		if (isManagerOrOwner) {
			return org.orgId;
		}
	}

	return;
};
