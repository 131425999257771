import { Box, Flex, SkeletonText, Stack, Text } from '@chakra-ui/react';
import { Analysis, SolvedStatus } from '@jam/api-types';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../common/components/custom-table/CustomTable';
import { useGetContentAllocated } from '../../../common/hooks/useGetContentAllocated';
import {
	useGetJourneyDetailsQuery,
	useGetUserStoriesQuery,
} from '../../../redux/api/cmsApi';
import {
	useGetAllSessionsQuery,
	useGetUserJourneyContentAllocationQuery,
} from '../../../redux/api/learnerApi';

type HistoryItem = {
	story: string;
	storyId: string;
	sessionId: string;
	type: string;
	track: string;
	date: Date;
	status: Analysis | null;
};

export const SolvedStatusToColorMap: Record<SolvedStatus, string> = {
	[SolvedStatus.Solved]: '#73F5A2',
	[SolvedStatus.PartlySolved]: '#F8DE00',
	[SolvedStatus.NotSolved]: '#FF6767',
	[SolvedStatus.Undetected]: 'gray.500',
};

const LoadingState = () => {
	return (
		<Flex mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} spacing={'5'} />
		</Flex>
	);
};

export const HistoryTable = () => {
	const { t } = useTranslation('history');
	// get all the sessions
	const { data, isLoading: sessionsLoading } = useGetAllSessionsQuery(
		undefined,
		{
			refetchOnMountOrArgChange: true,
		}
	);
	// get the user journey, need to get the journeyId
	const { data: userJourney, isLoading: journeyLoading } =
		useGetUserJourneyContentAllocationQuery();
	const contentAllocated = useGetContentAllocated();
	// get the journey details to get the tracks
	const { data: journeyDetails } = useGetJourneyDetailsQuery(
		userJourney?.journeyReferenceId || '',
		{
			skip: !userJourney?.journeyReferenceId,
		}
	);
	const navigate = useNavigate();
	// get all the stories to get the story details
	const { data: stories } = useGetUserStoriesQuery();

	if (sessionsLoading || journeyLoading) return <LoadingState />;

	if (!data || !journeyDetails || !stories || data.length === 0) return null;

	const sessions: HistoryItem[] = data
		.map((session) => {
			// get the story details
			const story = stories?.find((s) => s.id === session.storyReferenceId);

			const { tracks } = journeyDetails;

			// get the first track which has the story
			const track = tracks.find((tr) =>
				tr.stories.some((s) => s.id === session.storyReferenceId)
			);
			let type = t('from-gym');

			if (session.contentAllocationId) {
				const content = contentAllocated.find(
					(c) => c.id === session.contentAllocationId
				);
				type =
					content?.type === 'journey' ? t('from-journey') : t('from-refresher');
			}

			if (!story) return null;

			// return the history item object
			return {
				storyId: session.storyReferenceId,
				story: story.title,
				sessionId: session.id,
				type,
				track: track?.title || '',
				date: new Date(session.createdAt),
				status: session.analysis,
			};
		})
		.filter((item) => item !== null) as HistoryItem[];

	const columnHelper = createColumnHelper<HistoryItem>();
	const columns = [
		columnHelper.accessor('story', {
			header: t('mission').toString(),
			cell: ({ row }) => (
				<Text fontWeight={'medium'}>{row.original.story}</Text>
			),
		}),
		columnHelper.accessor('type', {
			header: t('type').toString(),
			cell: ({ row }) => <Text fontWeight={'medium'}>{row.original.type}</Text>,
		}),
		columnHelper.accessor('track', {
			header: t('track').toString(),
			cell: ({ row }) => (
				<Text fontWeight={'medium'}>{row.original.track}</Text>
			),
		}),
		columnHelper.accessor('date', {
			header: t('date').toString(),
			cell: ({ row }) => (
				<Text fontWeight={'medium'}>{format(row.original.date, 'MMM dd')}</Text>
			),
		}),
		columnHelper.accessor('status', {
			header: t('feedback').toString(),
			cell: ({ row }) => {
				const status = row.original.status;
				if (status === null) return null;
				return (
					<Stack direction={'row'} spacing={'4px'}>
						{status.items.map((item) => {
							return (
								<Box
									w="13px"
									h="13px"
									bg={
										item.solved
											? SolvedStatusToColorMap[item.solved]
											: SolvedStatusToColorMap[SolvedStatus.Undetected]
									}
									borderRadius={'full'}
									key={item.id}
								/>
							);
						})}
					</Stack>
				);
			},
		}),
	];

	return (
		<Box mt="5">
			<Text
				w="fit-content"
				fontSize="2xl"
				color={'#757575'}
				fontWeight="medium"
			>
				{t('learning_history')}
			</Text>
			<Box
				borderRadius={'24px'}
				mt="5"
				py="10"
				px="4"
				border={'1px'}
				borderColor={'#D9D9D9'}
			>
				<CustomTable<HistoryItem>
					data={sessions}
					columns={columns}
					onRowClick={(item) => {
						navigate(
							`/call?storyId=${item.storyId}&sessionId=${item.sessionId}&ref=history&activeCallTabIndex=1`
						);
					}}
				/>
			</Box>
		</Box>
	);
};
