import { AppLayout } from '../common/components/AppLayout';
import {
	Tabs,
	TabList,
	Tab,
	TabIndicator,
	Box,
	Text,
	TabPanels,
	TabPanel,
} from '@chakra-ui/react';
import { OverviewScreen } from '../features/dashboard/manager/components/screens/Overview/OverviewScreen';
import { UsageScreen } from '../features/dashboard/manager/components/screens/UsageScreen/UsageScreen';
import { TrainingPerformanceScreen } from '../features/dashboard/manager/components/screens/TrainingPerformance/TrainingPerformanceScreen';
import { TeamPerformanceScreen } from '../features/dashboard/manager/components/screens/TeamPerformance/TeamPerformanceScreen';
import { useActiveScreen } from '../features/dashboard/hooks/useActiveScreen';

export const ManagerDashboard = () => {
	const { activeScreen, setActiveScreen } = useActiveScreen();
	return (
		<AppLayout>
			<Box bg="white" p="10">
				<Text mb="5" fontWeight={'semibold'} fontSize="2xl">
					Manager Dashboard (* Preview)
				</Text>
				<Tabs index={activeScreen ?? 0} isLazy size={'lg'} variant="indicator">
					<TabList>
						<Tab onClick={() => setActiveScreen(null)}>Overview</Tab>
						<Tab onClick={() => setActiveScreen(1)}>Usage</Tab>
						<Tab onClick={() => setActiveScreen(2)}>Training Performance</Tab>
						<Tab onClick={() => setActiveScreen(3)}>Team Performance</Tab>
					</TabList>
					<TabIndicator />
					<TabPanels>
						<TabPanel>
							<OverviewScreen />
						</TabPanel>
						<TabPanel>
							<UsageScreen />
						</TabPanel>
						<TabPanel>
							<TrainingPerformanceScreen />
						</TabPanel>
						<TabPanel>
							<TeamPerformanceScreen />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</AppLayout>
	);
};
