import { Stack } from '@chakra-ui/react';
import { AppLayout } from '../common/components/AppLayout';
import { CallDetails } from '../features/call/components/CallDetails';
import { useActiveStory } from '../features/home/hooks/useActiveStory';
import { useGetStoryDetailsQuery } from '../redux/api/cmsApi';
import {
	useGetSessionDetailsQuery,
	useGetStoryLastSessionQuery,
	useGetStorySessionsQuery,
	useLazyGetUserTotalExperienceQuery,
} from '../redux/api/learnerApi';
import { useEffect, useState } from 'react';
import { Breadcrumbs } from '../features/call/components/Breadcrumbs';
import { useReferer } from '../features/home/hooks/useReferer';
import { useTrack } from '../features/home/hooks/useTrack';
import { useActiveSession } from '../features/call/hooks/useActiveSession';
import { Caller } from '../features/call/components/Caller';
import { useActiveContentAllocationId } from '../common/hooks/useActiveContentAllocationId';
import { AnalysisState, SolvedStatus } from '@jam/api-types';

const RefToBreadcrumb: Record<
	string,
	{
		text: string;
		to: string;
	}
> = {
	gym: {
		text: 'Gym',
		to: '/gym',
	},
	history: {
		text: 'History',
		to: '/users/history',
	},
	home: {
		text: 'Home',
		to: '/users/home',
	},
};

export const CallPage = () => {
	const { activeStory } = useActiveStory();
	const { ref } = useReferer();
	const { track } = useTrack();
	const { activeContentAllocationId } = useActiveContentAllocationId();
	const { activeSession } = useActiveSession();
	const [polling, setPolling] = useState(0);
	const [getUserTotalExperience] = useLazyGetUserTotalExperienceQuery();
	const { data: story } = useGetStoryDetailsQuery(activeStory || '', {
		skip: !activeStory,
	});

	const { data: storySessions } = useGetStorySessionsQuery(activeStory || '', {
		skip: !activeStory,
	});

	const { data: lastSession } = useGetStoryLastSessionQuery(activeStory || '', {
		// skip the query if there is no active story or if there is an active session
		skip: !activeStory || !!activeSession,
		pollingInterval: polling,
	});
	const { data: currentSession } = useGetSessionDetailsQuery(
		activeSession || '',
		{
			skip: !activeSession,
		}
	);

	const session = activeSession ? currentSession : lastSession;

	// This useEffect is used to set the polling interval for the last session query based on the status of the last session analysis object.
	useEffect(() => {
		if (!session) {
			return;
		}
		if (
			session.analysis === null ||
			session.analysis.state !== AnalysisState.DONE
		) {
			setPolling(3000);
		} else {
			setPolling(0);
			void getUserTotalExperience();
		}
	}, [setPolling, session]);

	if (!story) {
		return null;
	}
	const breadCrumbItems = [];

	if (ref) {
		const item = RefToBreadcrumb[ref];
		if (item) {
			breadCrumbItems.push(item);
		}
	}

	if (track) {
		breadCrumbItems.push({
			text: track,
		});
	}

	breadCrumbItems.push({
		text: story.title,
	});

	const analysis = session?.analysis;
	const completedItems =
		analysis?.items.filter((item) => item.solved === SolvedStatus.Solved)
			.length || 0;
	const allItems = analysis?.items.length || 0;
	return (
		<AppLayout>
			<Stack direction="column" h="100%" spacing={10} p={4}>
				<Breadcrumbs items={breadCrumbItems} />
				<Caller
					numberOfRounds={storySessions?.length || 0}
					isReturningCall={!!session}
					contentAllocationId={activeContentAllocationId}
					story={story}
					conversationType={story.situation.conversation_type}
					completedItems={completedItems}
					allItems={allItems}
				/>
				<CallDetails
					completedItems={completedItems}
					allItems={allItems}
					story={story}
					session={session}
				/>
			</Stack>
		</AppLayout>
	);
};
