import { UsageByWeekChart } from './UsageByWeekChart';
import { Box, Flex } from '@chakra-ui/react';
import { UsageByUserTable } from './UsageByUserTable';

export const UsageScreen = () => {
	return (
		<Flex gap={'10'} mt="5" direction={'column'}>
			<Box w="fit-content" pr="10" borderRadius={'lg'} bg="gray.50">
				<UsageByWeekChart width={500} />
			</Box>
			<UsageByUserTable />
		</Flex>
	);
};
