import {
	useGetJourneyContentAllocationListQuery,
	useGetRefreshersQuery,
} from '../../redux/api/learnerApi';

type Content = {
	id: string;
	type: 'journey' | 'refresher';
};

export const useGetContentAllocated = () => {
	const { data: journeys } = useGetJourneyContentAllocationListQuery();
	const { data: refreshers } = useGetRefreshersQuery();
	const content: Content[] = [];
	if (journeys) {
		journeys.forEach((journey) => {
			content.push({
				id: journey.id,
				type: 'journey',
			});
		});
	}

	if (refreshers) {
		refreshers.forEach((refresher) => {
			content.push({
				id: refresher.id,
				type: 'refresher',
			});
		});
	}

	return content;
};
